import { InfoCircleOutlined } from '@ant-design/icons';
import { Alert, Checkbox, Input, Modal, notification, Spin } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './PaymentReceivingDialog.scss';
import axios from 'axios';
import { API_ENDPOINTS } from 'assets/api/endpoints';
import { getUserInfo } from 'helpers/localStorageHandler';
import { getUserLanguageInCookie } from 'helpers/cookiesHandler';
import { useConfigurationContext } from 'store/configurationContext';
import { CompanyName } from 'constant/fallback-config';
import { convertMilliseconds } from 'helpers/getRemainingTime';

const numberFormatter = (num: number) => {
	return `${num > 9 ? '' : '0'}${num}`;
};

const OTP_LENGTH = 6;
export function PaymentReceivedDialog({
	showPaymentReceivedDialog,
	setShowPaymentReceivedDialog,
	handelPaymentReceived,
	handelFinishedSuccessfully,
	isFinished,
	quantity,
	amount,
	userName,
	orderNumber,
	otpVlaid,
	loading,
}: {
	showPaymentReceivedDialog: boolean;
	setShowPaymentReceivedDialog: (value: boolean) => void;
	handelPaymentReceived: (otp: string) => void;
	handelFinishedSuccessfully: (otp: string) => void;
	isFinished: boolean;
	quantity: string;
	userName: string;
	amount: string;
	orderNumber: string;
	otpVlaid: { value: boolean };
	loading: boolean;
}) {
	const { t } = useTranslation();
	const [isFirstChecked, setIsFirstChecked] = useState(false);
	const [isSecondChecked, setIsSecondChecked] = useState(false);
	const [isThirdChecked, setIsThirdChecked] = useState(false);
	const [otp, setOtp] = useState(['', '', '', '', '', '']);
	const [minutes, setMinutes] = useState('0');
	const [seconds, setSeconds] = useState('0');
	const [expireTime, setExpireTime] = useState(0);
	const [currentCountdown, setCurrentCountdown] = useState(0);
	const [countdownWorker, setCountdownWorker] = useState<Worker | null>(null);
	const token = getUserInfo()?.token;

	const { configurationState } = useConfigurationContext();

	const handleOtpChange = (index: number, value: string) => {
		const newOtp = [...otp];
		newOtp[index] = value;
		// Move to the next input field if a digit is entered
		if (value !== '' && index < 6) {
			const nextInput = document.getElementById(`otp-input-${index + 1}`) as HTMLInputElement | null;
			if (nextInput) {
				nextInput.focus();
			}
		}
		if (value === '') {
			const nextInput = document.getElementById(`otp-input-${index - 1}`) as HTMLInputElement | null;
			if (nextInput) {
				nextInput.focus();
			}
		}

		setOtp(newOtp);
	};

	const sendOtp = (resend?: boolean) => {
		axios(`${API_ENDPOINTS.getOtp.replace('%orderNumber%', orderNumber)}`, {
			method: 'POST',
			headers: { Authorization: `Bearer ${token}` },
		}).then((res) => {
			if (resend) {
				notification['success']({ message: '', description: t<string>('otpResendMessage') });
			}
			setExpireTime(res.data.remainingExpiryTime);
		});
	};

	useEffect(() => {
		setOtp(['', '', '', '', '', '']);
		if (showPaymentReceivedDialog) {
			sendOtp();
		}
	}, [showPaymentReceivedDialog]);

	useEffect(() => {
		let worker = new Worker('./countDownMillieSeconds.js');
		worker.postMessage({ type: 'init', countdown: expireTime | 0 });
		worker.onmessage = function (e) {
			if (e.data.type === 'countdown') {
				setCurrentCountdown(e.data.countdown);
			} else if (e.data.type === 'completed') {
				setShowPaymentReceivedDialog(false);
				setIsFirstChecked(false);
				setIsSecondChecked(false);
				setIsThirdChecked(false);
			}
		};

		setCountdownWorker(worker);
		return () => {
			worker.terminate();
		};
	}, [expireTime]);

	useEffect(() => {
		const { hours, minutes, seconds } = convertMilliseconds(currentCountdown);
		if (hours <= 0 && minutes <= 0 && seconds <= 0) {
			setShowPaymentReceivedDialog(false);
		}
		setMinutes(numberFormatter(Math.floor(minutes)));
		setSeconds(numberFormatter(seconds));
	}, [currentCountdown]);

	useEffect(() => {
		if (otpVlaid.value) {
			setShowPaymentReceivedDialog(false);
			setIsFirstChecked(false);
			setIsSecondChecked(false);
			setIsThirdChecked(false);
		}
	}, [otpVlaid]);
	const Company_Name = configurationState.find((item) => item.configKey === 'Company_Name')?.value || '';
	return (
		<Modal
			onCancel={() => setShowPaymentReceivedDialog(false)}
			maskClosable={false}
			open={showPaymentReceivedDialog}
			footer={null}
			title={
				<div>
					<InfoCircleOutlined style={{ fontSize: '40px', textAlign: 'center', margin: '5px auto', display: 'block' }} />
					<h4 className='mt-2'>{t<string>('conofirmYouReceivedPayment')}</h4>
					<Alert
						description={t<string>('inzoDoesNotProvideAutoRefund', { comapnyName: Company_Name })}
						type='info'
						showIcon
					/>
				</div>
			}
		>
			<ul id='payment-received-dialog' style={{ listStyleType: 'none' }} className='payment-receiving-dialog-list'>
				<li>
					<Checkbox
						id='is-first-checked'
						checked={isFirstChecked}
						onChange={(e) => setIsFirstChecked(e.target.checked)}
						style={{ marginBottom: '20px' }}
					>
						{t<string>('iReceivedAmountFromBuyer', { amount })}
					</Checkbox>
				</li>
				<li>
					<Checkbox
						id='is-second-checked'
						checked={isSecondChecked}
						onChange={(e) => setIsSecondChecked(e.target.checked)}
						style={{ marginBottom: '20px' }}
					>
						{t<string>('iCheckedPaymentFrom', { userName })}
					</Checkbox>
				</li>
				<li>
					<Checkbox
						id='is-third-checked'
						checked={isThirdChecked}
						onChange={(e) => setIsThirdChecked(e.target.checked)}
						style={{ marginBottom: '20px' }}
					>
						{t<string>('iAgreeToReleasePaymentToBuyer')}
					</Checkbox>
				</li>
			</ul>
			<p>{t<string>('enterYourOtp')}</p>
			<div className='mb-4 flex xss:flex-col md:flex-row'>
				<div className='xss:mb-2 md:mb-0' style={{ direction: 'ltr', marginBlockStart: '5px' }}>
					{otp.map((digit, index) => (
						<Input
							key={index}
							id={`otp-input-${index}`}
							value={digit}
							onChange={(e) => handleOtpChange(index, e.target.value)}
							maxLength={1}
							style={{ width: '2rem', textAlign: 'center' }}
							className='otp-digit'
							onPaste={(e) => {
								const copiedValue = e.clipboardData.getData('text/plain').split('');
								const otpValue =
									copiedValue.length > OTP_LENGTH
										? copiedValue.slice(0, OTP_LENGTH)
										: [...copiedValue, ...Array(OTP_LENGTH - copiedValue.length).fill('')];
								setOtp(otpValue);
							}}
						/>
					))}
				</div>
				<div className='xss:m-0 sm:m-auto'>
					{t<string>('expireIn')}:{' '}
					{getUserLanguageInCookie() === 'ar' ? (
						<>
							<span>{seconds}</span> : <span>{minutes}</span>{' '}
						</>
					) : (
						<>
							<span>{minutes}:</span> <span>{seconds}</span>
						</>
					)}
				</div>
			</div>
			<div>
				<p>
					{t<string>('didNotreceiveOtp')}
					<span className='text-[#0184ff] underline cursor-pointer' onClick={() => sendOtp(true)}>
						{t<string>('resend')}
					</span>
				</p>
			</div>
			<Spin spinning={loading}>
				<button
					id='order-life-cycle_action-button-dialog_payment-received-confirm'
					disabled={!isFirstChecked || !isSecondChecked || !isThirdChecked || otp.some((value) => value === '')}
					className='form-btn'
					style={{ cursor: 'pointer', margin: '0 auto', maxWidth: '100px', height: '36px', fontWeight: 400 }}
					onClick={() => {
						if (isFinished) {
							handelFinishedSuccessfully(otp.join(''));
						} else {
							handelPaymentReceived(otp.join(''));
						}
					}}
				>
					{t<string>('confirm')}
				</button>
			</Spin>
		</Modal>
	);
}
