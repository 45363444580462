import { useMemo, useState } from 'react';
import { useCountriesContext } from '../../../../../../store/CountriesContext';
import { useTranslation } from 'react-i18next';
import { OrderType } from '../../../../types/OrderType';
import { getUserInfo } from '../../../../../../helpers/localStorageHandler';
import { Skeleton, notification } from 'antd';
import { CopyFilled, DownloadOutlined } from '@ant-design/icons';
import axios from 'axios';
import { API_ENDPOINTS } from 'assets/api/endpoints';
import { PhotoPreview } from './PhotoPreview';
import { OrderSource } from 'pages/OrderLifeCycle/types/OrderSource';
import { WalletDetails } from 'models/WalletDetails';
import { Link } from 'react-router-dom';

export const decodeBase64 = (base64String: string) => {
	const binaryString = window.atob(base64String);
	const bytes = new Uint8Array(binaryString.length);

	for (let i = 0; i < binaryString.length; i++) {
		bytes[i] = binaryString.charCodeAt(i);
	}

	return bytes;
};

type PaymentMethodDetailsProps = {
	paymentMehodId: number;
	accountNNumber: string;
	amount: string;
	orderType: OrderType;
	paymentProof: boolean;
	paymentProofFileId: string;
	paymentProofFileLabel: string;
	orderNumber: string;
	accountGroup: string;
	source: OrderSource;
	walletDetails: WalletDetails;
};

const CHAR_SIZE = 5;
function PaymentMethodDetails({
	paymentMehodId,
	accountNNumber,
	amount,
	orderType,
	paymentProof,
	paymentProofFileId,
	paymentProofFileLabel,
	orderNumber,
	accountGroup,
	source,
	walletDetails,
}: PaymentMethodDetailsProps) {
	const { t } = useTranslation();
	const { countriesState } = useCountriesContext();
	const isMerchant = useMemo(() => getUserInfo()?.isMerchant ?? false, []);
	const [showPreviewPhoto, setShowPreviewPhoto] = useState(false);
	const [photo, setPhoto] = useState('');
	const token = getUserInfo()?.token;
	const [pdfUrl, setPdfUrl] = useState('');
	const paymentMethod = useMemo(
		() => countriesState.paymentMethods.find((method) => method.methodId === paymentMehodId),
		[paymentMehodId, countriesState.paymentMethods],
	);
	const isSell = useMemo(() => orderType === 'SELL', [orderType]);
	const amountLabel = useMemo(
		() => ((isMerchant && !isSell) || (!isMerchant && isSell) ? t<string>('YouReceive') : t<string>('Youpay')),
		[isMerchant, isSell, t],
	);

	const copyMyText = (value: string) => {
		let textToCopy = document.getElementById(value) as HTMLInputElement;
		if (textToCopy) {
			navigator.clipboard.writeText(textToCopy.innerHTML);
			notification['success']({ message: '', description: t<string>('copiedSuccessfully') });
		}
	};
	let tremName = paymentProofFileId.split('.');
	let FileName =
		tremName[0].length > CHAR_SIZE
			? tremName[0].slice(0, CHAR_SIZE) + '..' + tremName[tremName.length - 1]
			: paymentProofFileId;

	const downloadFile = () => {
		axios
			.get(
				API_ENDPOINTS.downloadFilePaymentProof
					.replace('%orderNumber%', orderNumber)
					.replace('%fileName%', paymentProofFileLabel),
				{
					responseType: 'arraybuffer',
					headers: { Authorization: `Bearer ${token}` },
				},
			)
			.then((response) => {
				const base64 = btoa(new Uint8Array(response.data).reduce((data, byte) => data + String.fromCharCode(byte), ''));
				if (paymentProofFileLabel.includes('pdf')) {
					fetchPdf(base64);
				} else {
					setPhoto(base64);
				}
			})
			.catch((err) => console.error(err));
	};

	const fetchPdf = (base64Pdf: string) => {
		// Decode the base64 string to Uint8Array
		const uint8Array = decodeBase64(base64Pdf);

		// Create a blob from Uint8Array
		const blob = new Blob([uint8Array], { type: 'application/pdf' });

		// Create an object URL for the blob
		const objectURL = URL.createObjectURL(blob);

		// Set the PDF URL for the viewer
		setPdfUrl(objectURL);
	};
	return (
		<div className='order-details-wrapper'>
			<div className='details-row'>
				<h3 className='order-details-title'>{t<string>('paymentDetails')}</h3>
				{paymentProof && (
					<div
						className='uploadedFile rounded-2xl flex items-center'
						id='order-life-cycle_uploaded_file'
						onClick={() => {
							setShowPreviewPhoto(true);
							downloadFile();
						}}
					>
						<DownloadOutlined style={{ color: '#018aff', fontSize: '18px', margin: '5px 8px' }} />
						{FileName}
					</div>
				)}
			</div>
			{amount !== 'undefined' ? (
				<>
					<div className='details-row'>
						<p>{amountLabel}</p>
						<div className='flex'>
							<p className='value' id='order-life-cycle_details_you-pay'>
								{amount}
							</p>
							<CopyFilled className='mx-2 cursor-pointer' onClick={() => copyMyText('pay')} />
						</div>
					</div>
					<div className='details-row'>
						<p>{t<string>('paymentMethod')}</p>
						<p className='value'>
							<span
								id='order-life-cycle_details_payment-method'
								style={{ color: paymentMethod?.color, background: paymentMethod?.bgColor }}
								className={`text-[10px] py-1 px-1 rounded-sm`}
							>
								{paymentMethod?.methodName}
							</span>
						</p>
					</div>
					{isMerchant ? null : (
						<div className='details-row'>
							<p>{source === OrderSource.TRADING_ACCOUNT ? t<string>('account') : t<string>('WALLET')}</p>
							<div className='flex'>
								{source === OrderSource.TRADING_ACCOUNT ? (
									<p className='value' id='accountNNumber'>
										{accountGroup} | ${accountNNumber}
									</p>
								) : (
									<Link to={`/wallet-accounts`} className='hover:text-black text-black'>
										{' '}
										<p className='value' id='order-life-cycle_details_value'>
											<span id='order-life-cycle_details_currency'>{walletDetails?.currency}</span> |{' '}
											<span id='order-life-cycle_details_account_no'>{walletDetails?.number}</span>
										</p>
									</Link>
								)}
								<CopyFilled className='mx-2 cursor-pointer' onClick={() => copyMyText('accountNNumber')} />
							</div>
						</div>
					)}
					<PhotoPreview
						showModal={showPreviewPhoto}
						setShowModal={setShowPreviewPhoto}
						photo={photo}
						paymentProofFileId={paymentProofFileId}
						pdfUrl={pdfUrl}
					/>
				</>
			) : (
				<Skeleton active paragraph={{ rows: 1 }} />
			)}
		</div>
	);
}

export default PaymentMethodDetails;
