import ReactTimeAgo from 'react-time-ago';
import { MessageType } from '../../../../types/MessageType';
import ActionState from '../../../OrderLifeCycleState/State/ActionState';
import { useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import { API_ENDPOINTS } from 'assets/api/endpoints';
import { getUserInfo } from 'helpers/localStorageHandler';
import { CheckOutlined } from '@ant-design/icons';
import { getUserLanguageInCookie } from 'helpers/cookiesHandler';
import { getLanguage } from 'helpers/getLanguage';
import { getInitials } from 'helpers/getStringInitials';
import { useTranslation } from 'react-i18next';
import { useDirectionContext } from 'store/DirectionContext';
import { PhotoPreview } from '../../BodyDetails/PaymentMethodDetails/PhotoPreview';
import { useConfigurationContext } from 'store/configurationContext';

type MessageItemProps = {
	isMerchant: boolean;
	message: MessageType;
	orderNumber: string;
	userName: string;
};

function convertURL(inputString: string) {
	// Regular expression to match URLs
	const urlPattern = /(https?:\/\/[^\s]+)/g;

	// Split the input string based on the URLs
	const parts = inputString.split(urlPattern);

	// Map through the parts and replace URLs with anchor tags
	return parts.map((part, index) => {
		// Check if the part matches a URL
		if (urlPattern.test(part)) {
			return (
				<a key={index} href={part} target='_blank' rel='noopener noreferrer'>
					{part}
				</a>
			);
		}

		// Return plain text if not a URL
		return part;
	});
}

function isImage(url: string) {
	return /(\.png|\.jpg|\.jpeg|\.gif|\.webp|\.bmp|\.svg)$/i.test(url);
}

function MessageItem({ isMerchant, message, orderNumber, userName }: MessageItemProps) {
	const [image, setImage] = useState('');
	const [pdfUrl, setPdfUrl] = useState('');
	const [showModal, setShowModal] = useState(false);
	const { t } = useTranslation();
	const token = getUserInfo()?.token;
	const { directionState } = useDirectionContext();
	const { configurationState } = useConfigurationContext();
	const themeColor = configurationState.find((item) => item.configKey === 'theme_color')?.value || '';
	const secondary_color = configurationState.find((item) => item.configKey === 'secondary_color')?.value || '';

	useEffect(() => {
		if (!showModal) {
			setPdfUrl('');
			setImage('');
		}
	}, [showModal]);

	const downloadFile = (event: any) => {
		setShowModal(true);
		axios
			.get(API_ENDPOINTS.getFileUrl.replace('%orderNumber%', orderNumber).replace('%fileName%', message.fileId), {
				responseType: 'arraybuffer',
				headers: { Authorization: `Bearer ${token}` },
			})
			.then((response) => {
				const base64 = btoa(new Uint8Array(response.data).reduce((data, byte) => data + String.fromCharCode(byte), ''));
				// Create a blob from Uint8Array
				const blob = new Blob([response.data], { type: 'application/pdf' });
				// Create an object URL for the blob
				const objectURL = URL.createObjectURL(blob);
				// Set the PDF URL for the viewer
				isImage(message.fileLabel) ? setImage(base64) : setPdfUrl(objectURL);
				const a = document.createElement('a');
				a.href = `data:image/jpeg;base64,${base64}`;
				a.download = event.target.innerText;
				// a.click();
			})
			.catch(() => setShowModal(false));
	};

	const messageBy = useMemo(() => {
		if (message.role === 'ADMIN' || message.role === 'SUPER_USER') {
			return 'admin';
		} else if ((message.role === 'MERCHANT' && isMerchant) || (message.role === 'CLIENT' && !isMerchant)) {
			return 'mine';
		} else if ((message.role === 'CLIENT' && isMerchant) || (message.role === 'MERCHANT' && !isMerchant)) {
			return 'theirs';
		}
	}, [isMerchant, message]);

	if (message.type === 'ACTION') {
		return (
			<div className={`chat-message-container`}>
				<ActionState actionState={message.clientAction || message.merchantAction || message.adminAction} size='small' />
				<span className={`time`}>
					{message.timestamp ? (
						<ReactTimeAgo
							date={new Date(message.timestamp).getTime()}
							locale={getLanguage(getUserLanguageInCookie())}
						/>
					) : (
						<></>
					)}
					{message.read ? (
						<>
							<CheckOutlined style={{ color: 'green' }} />
							<CheckOutlined style={{ color: 'green' }} />
						</>
					) : (
						<>
							<CheckOutlined />
							<CheckOutlined />
						</>
					)}
				</span>
			</div>
		);
	} else if (message.type === 'TEXT') {
		return (
			<div className={`chat-message-container relative ${messageBy}`}>
				{messageBy === 'admin' && <div className='mx-9 my-1 text-xs'>{`${t('agent')} ${message.fullName}`}</div>}
				{messageBy === 'theirs' && <div className='mx-9 my-1 text-xs'>{message.fullName}</div>}
				{messageBy === 'mine' && <div className='text-xs my-1 flex justify-end'>{message.fullName}</div>}
				<div
					className={`chat-message-container relative ${messageBy}`}
					style={{ ...(messageBy === 'theirs' ? { minWidth: 'auto', width: 'auto', maxWidth: '100%' } : {}) }}
				>
					{messageBy === 'theirs' && (
						<div className='w-[30px] h-[30px] rounded-full border-[1px] border-solid border-black flex items-center justify-center absolute top-[-5px] '>
							{getInitials(message.fullName)}
						</div>
					)}
					<p
						style={
							messageBy === 'theirs'
								? {
										marginInlineStart: '35px',
										borderBottomLeftRadius: directionState === 'ltr' ? 0 : '8px',
										borderBottomRightRadius: directionState === 'rtl' ? 0 : '8px',
								  }
								: messageBy === 'admin'
								? {
										color: '#fff',
										backgroundColor: 'green',
								  }
								: {
										borderBottomLeftRadius: directionState === 'ltr' ? '8px' : 0,
										borderBottomRightRadius: directionState === 'rtl' ? '8px' : 0,
										color: secondary_color,
										backgroundColor: themeColor,
								  }
						}
					>
						{convertURL(message.content)}
					</p>
					<span className={`time time-text`}>
						{message.timestamp ? (
							<ReactTimeAgo
								date={new Date(message.timestamp).getTime()}
								locale={getLanguage(getUserLanguageInCookie())}
							/>
						) : (
							<></>
						)}
						{message.read ? (
							<>
								<CheckOutlined style={{ color: 'green' }} />
								<CheckOutlined style={{ color: 'green' }} />
							</>
						) : (
							<>
								<CheckOutlined />
								<CheckOutlined />
							</>
						)}
					</span>
				</div>
			</div>
		);
	} else if (message.type === 'FILE') {
		return (
			<div className={`chat-message-container ${messageBy}`}>
				<button
					style={
						messageBy === 'theirs'
							? {
									color: '#00142a',
									backgroundColor: '#ebebeb',
									padding: '5px 10px',
									textDecoration: 'underline',
									opacity: '.9',
							  }
							: messageBy === 'admin'
							? {
									color: '#fff',
									backgroundColor: 'green',
									padding: '5px 10px',
									textDecoration: 'underline',
									opacity: '.9',
							  }
							: {
									color: secondary_color,
									backgroundColor: themeColor,
									padding: '5px 10px',
									textDecoration: 'underline',
									opacity: '.9',
							  }
					}
					onClick={downloadFile}
				>
					{message.fileLabel}
				</button>
				<span className={`time`}>
					{message.timestamp ? (
						<ReactTimeAgo
							date={new Date(message.timestamp).getTime()}
							locale={getLanguage(getUserLanguageInCookie())}
						/>
					) : (
						<></>
					)}
					{message.read ? (
						<>
							<CheckOutlined style={{ color: 'green' }} />
							<CheckOutlined style={{ color: 'green' }} />
						</>
					) : (
						<>
							<CheckOutlined />
							<CheckOutlined />
						</>
					)}
				</span>
				<PhotoPreview
					showModal={showModal}
					setShowModal={setShowModal}
					photo={''}
					paymentProofFileId={message.fileLabel}
					pdfUrl={pdfUrl}
				/>
			</div>
		);
	} else if (message.type === 'IMAGE') {
		return (
			<div className={`chat-message-container ${messageBy}`}>
				<div
					className='rounded-lg p-[6px] w-[75%] flex justify-end'
					style={
						messageBy === 'theirs'
							? {
									color: '#00142a',
									backgroundColor: '#ebebeb',
							  }
							: messageBy === 'admin'
							? {
									color: '#fff',
									backgroundColor: 'green',
							  }
							: {
									color: secondary_color,
									backgroundColor: themeColor,
							  }
					}
				>
					<img
						src={message.fileUrl}
						onClick={downloadFile}
						className='rounded-lg'
						alt='image'
						style={{ width: '100%', cursor: 'pointer' }}
					/>
				</div>
				<span className={`time`}>
					{message.timestamp ? (
						<ReactTimeAgo
							date={new Date(message.timestamp).getTime()}
							locale={getLanguage(getUserLanguageInCookie())}
						/>
					) : (
						<></>
					)}
					{message.read ? (
						<>
							<CheckOutlined style={{ color: 'green' }} />
							<CheckOutlined style={{ color: 'green' }} />
						</>
					) : (
						<>
							<CheckOutlined />
							<CheckOutlined />
						</>
					)}
				</span>
				<PhotoPreview
					showModal={showModal}
					setShowModal={setShowModal}
					photo={image}
					paymentProofFileId={message.fileLabel}
					pdfUrl={''}
				/>
			</div>
		);
	}
	return <></>;
}

export default MessageItem;
