import React, { useEffect, useState } from 'react';
import './Navbar.scss';
import { getMenuItems } from '../../helpers/menuItems';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMainContext } from '../../store/MainContext';

import NavBarIcons from './NavBarIcons';
import { useConfigurationContext } from 'store/configurationContext';
import { Grid } from 'antd';
import { useHoliday } from 'store/HolidayContext';
import axios from 'axios';
import { API_ENDPOINTS } from 'assets/api/endpoints';

const Navbar = () => {
	const { t } = useTranslation();
	const { mainState } = useMainContext();
	const { configurationState } = useConfigurationContext();
	const [isBanner, setIsBanner] = useState(false);
	const Company_Name = configurationState.find((item) => item.configKey === 'Company_Name')?.value || '';

	const isLoggedIn = React.useMemo(() => mainState.isLoggedIn, [mainState.isLoggedIn]);
	const isMerchant = React.useMemo(() => mainState.isMerchant, [mainState.isMerchant]);
	const menuItems = React.useMemo(() => getMenuItems(isLoggedIn, isMerchant), [isLoggedIn, isMerchant]);
	const location = useLocation();
	const domainContainsInzo = window.location.hostname.includes('inzo');

	const [windowWidth, setWindowWidth] = useState(window.innerWidth);

	useEffect(() => {
		const handleResize = () => {
			setWindowWidth(window.innerWidth);
		};

		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	useEffect(() => {
		const fetchBanner = async () => {
			Promise.all([axios.get(API_ENDPOINTS.isTodayHolidy), axios.get(API_ENDPOINTS.getAnnoncement)]).then(
				([holidays, enhancements]: any) => {
					const comingHolidays = holidays.data.comingHolidays;
					const enhancementsArr = enhancements.data;
					setIsBanner(comingHolidays.length || enhancementsArr.length);
				},
			);
		};

		isLoggedIn && fetchBanner();
	}, [isLoggedIn]);

	const companyLogo = configurationState.find((item) => item.configKey === 'company_logo')?.value || '';
	const themeColor = configurationState.find((item) => item.configKey === 'theme_color')?.value || '';
	const secondary_color = configurationState.find((item) => item.configKey === 'secondary_color')?.value || '';

	return (
		<div
			style={{
				backgroundColor: themeColor,
				color: secondary_color,
				position: 'fixed',
				zIndex: 1000,
				width: '100%',
				top: isBanner ? '35px' : '0px',
			}}
			className='mx-auto px-[15px] md:px-[75px]  pt-2 flex flex-col md:flex-row items-center justify-between'
		>
			{/* Logo and Menu Items */}
			<div className='flex flex-col md:flex-row items-center justify-between w-full md:w-auto'>
				<div className='flex justify-between xss:w-full md:w-auto '>
					{companyLogo && (
						<>
							{!mainState.isLoggedIn ? (
								<Link to='/sign-in'>
									<img src={companyLogo} alt='logo' />
								</Link>
							) : (
								<Link to='/merchants'>
									<img src={companyLogo} alt='logo' />
								</Link>
							)}
						</>
					)}
					{windowWidth < 768 && <NavBarIcons />}
				</div>
				{isLoggedIn && (
					<div className='flex md:items-center md:ml-3 w-[100%] xss:justify-around xss:mb-2 md:mb-0'>
						{menuItems.map((i: any) => (
							<Link
								id={`navbar_link_${i.key}`}
								style={{ color: secondary_color }}
								className={`text-sm font-medium text-white opacity-100 md:mx-5 hover:opacity-70  transition-all duration-300 ${
									location.pathname === `/${i.key}` ? 'underline' : 'no-underline'
								}`}
								to={i.key}
								key={i.key}
							>
								{t(i.label)}
							</Link>
						))}
						{Company_Name.toLowerCase() === 'inzo' ? (
							<a
								href='https://my.inzo.co/login'
								target='_blank'
								rel='noreferrer'
								style={{ color: secondary_color }}
								className={`text-white text-sm font-medium opacity-95 flex justify-center md:mx-5 hover:opacity-70 hover:text-white transition-all duration-300 no-underline`}
							>
								{t('tradersRoom')}
							</a>
						) : null}
					</div>
				)}
			</div>
			{windowWidth >= 768 && <NavBarIcons />}
		</div>
	);
};

export default Navbar;
