import { FC, useEffect, useMemo, useState } from 'react';
import { Merchant, OfferType } from '../../models/Merchants';
import { getUserLanguageInCookie } from '../../helpers/cookiesHandler';
import { getInitials } from '../../helpers/getStringInitials';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { PaymentMethod } from '../../models/PaymentMethod';
import SelectPaymentMethods from '../SelectPaymentMethods/SelectPaymentMethods';
import './MerchantBuyAndSellModal.scss';
import { getUserInfo } from '../../helpers/localStorageHandler';
import axios from 'axios';
import { API_ENDPOINTS } from '../../assets/api/endpoints';
import { Tooltip, Spin, Modal } from 'antd';
import { CloseOutlined, ExclamationCircleOutlined, LikeFilled, DislikeFilled } from '@ant-design/icons';
import { PriceChangeModal } from './PriceChangeModal';
import { Wallet } from '../../models/Wallet';
import { useMainContext } from 'store/MainContext';
import { useConfigurationContext } from 'store/configurationContext';
import { useHoliday } from 'store/HolidayContext';

interface Props {
	merchant: Merchant;
	paymentMethods: PaymentMethod[];
	closeModal: () => void;
	offerType: OfferType;
	setRefersh: (value: boolean) => void;
	refersh: boolean;
	wallet: Wallet;
}

const REVIEW_PERCENTAGE = 80;
const MerchantBuyAndSellModal: FC<Props> = ({
	merchant,
	paymentMethods,
	closeModal,
	offerType,
	setRefersh,
	refersh,
	wallet,
}) => {
	const { t } = useTranslation();
	const { isTodayHoliday } = useHoliday();
	const navigate = useNavigate();
	const { mainState } = useMainContext();
	const [isAmountTouched, setIsAmountTouched] = useState(false);
	const [amount, setAmount] = useState<string | null | number>('');
	const [selectedMethod, setSelectedMethod] = useState('');
	const [canOpenOrder, setCanOpenOrder] = useState(false);
	const [youPayMessageError, setYouPayMessageError] = useState('');
	const [youRecieveMessageError, setYouRecieveMessageError] = useState('');
	const [noteError, setNoteError] = useState('');
	const [loading, setLoading] = useState(false);
	const [showModal, setShowModal] = useState(false);
	const [price, setPrice] = useState(merchant.rate);

	const { configurationState } = useConfigurationContext();

	const isBuy = useMemo(() => (offerType === OfferType.buy ? true : false), [offerType]);
	const truncateToThreeDecimalPlaces = (number: any) => {
		return Math.floor(number * 1000) / 1000;
	};
	const onAmountChange = (amountValue: string) => {
		setAmount(amountValue === '0' || amountValue === '' ? null : parseFloat(Number(amountValue).toFixed(3)));
		setIsAmountTouched(true);
	};
	const calculatedAmount = useMemo(() => {
		if (!amount) {
			return '0.00';
		}
		return isBuy ? truncateToThreeDecimalPlaces(+amount / price) : truncateToThreeDecimalPlaces(+amount * price);
	}, [isBuy, amount, price]);
	const offerPaymentMethods = useMemo(
		() => paymentMethods?.filter((method) => merchant.paymentMethods.includes(method.methodId)),
		[paymentMethods, merchant],
	);
	const [clientNote, setClientNote] = useState<String>('');
	const token = getUserInfo()?.token;

	const isUserAmountValid = useMemo(() => {
		if (Number(amount) <= 0) {
			setYouPayMessageError(t<string>('amountLessThanMerchantAvailable'));
			return false;
		}
		if (isBuy) {
			if (Number(amount) < merchant.min || Number(amount) > merchant.max) {
				return setYouPayMessageError(
					t<string>('amountNotSatisfyOfferLimit', {
						min: merchant.min,
						max: merchant.max,
						currency: merchant.currencyCode,
					}),
				);
			} else {
				setYouPayMessageError('');
			}
			if (Number(calculatedAmount) > Number(merchant.available)) {
				return setYouRecieveMessageError(
					t<string>('amountLessMerchnat', { available: merchant.available, currency: merchant.exchangeBaseCurrency }),
				);
			} else {
				setYouRecieveMessageError('');
			}
			if (Number(calculatedAmount) < Number(wallet.buyMin) || Number(calculatedAmount) > Number(wallet.buyMax)) {
				return setYouRecieveMessageError(
					t<string>('amountshouldliebetween', { min: wallet.buyMin, max: wallet.buyMax, currency: wallet.currency }),
				);
			} else {
				setYouPayMessageError('');
			}
			return true;
		} else {
			if (Number(calculatedAmount) < merchant.min || Number(calculatedAmount) > merchant.max) {
				return setYouRecieveMessageError(
					t<string>('amountNotSatisfyOfferLimit', {
						min: merchant.min,
						max: merchant.max,
						currency: merchant.currencyCode,
					}),
				);
			} else {
				setYouRecieveMessageError('');
			}
			if (Number(amount) > Number(wallet.balance)) {
				return setYouPayMessageError(t<string>('amountshouldLessThanwalletAvailable', { amount: wallet.balance }));
			} else {
				setYouPayMessageError('');
			}
			if (Number(amount) < Number(wallet.sellMin) || Number(amount) > Number(wallet.sellMax)) {
				return setYouPayMessageError(
					t<string>('amountshouldliebetween', {
						min: wallet.sellMin,
						max: wallet.sellMax,
						currency: wallet.currency,
					}),
				);
			} else {
				setYouPayMessageError('');
			}
			return true;
		}
	}, [amount, calculatedAmount, merchant.available, wallet, isBuy, clientNote, merchant.max, merchant.min, t]);

	const isOfferDisabled = useMemo(
		() =>
			!amount ||
			!selectedMethod ||
			!wallet ||
			!canOpenOrder ||
			!isUserAmountValid ||
			Boolean(noteError) ||
			mainState.userStatus === 'SUSPENDED',
		[amount, selectedMethod, wallet, canOpenOrder, isUserAmountValid, noteError, mainState],
	);

	const isDev = window.location.hostname.includes('devb');
	useEffect(() => {
		if (clientNote.length > 1000) {
			setNoteError(t<string>('noteErrorMessage'));
		} else {
			setNoteError('');
		}
	}, [clientNote, t]);
	useEffect(() => {
		axios
			.get(API_ENDPOINTS.canOpenOrder, { headers: { Authorization: `Bearer ${token}` } })
			.then((result) => setCanOpenOrder(result.data))
			.catch((error) => console.error(error));
	}, [token]);

	const navigateToOrderLifeCyclePage = async () => {
		let openOrderData = {
			type: isBuy ? 'BUY' : 'SELL',
			merchantId: merchant.merchantId,
			amount: isBuy ? Number(amount) : Number(calculatedAmount),
			quantity: isBuy ? Number(calculatedAmount) : Number(amount),
			paymentMethod: selectedMethod,
			note: clientNote,
			price: price,
			currency: wallet?.currency,
		};

		if (openOrderData && token) {
			setLoading(true);
			try {
				let exchangePriceChane = await axios.post(
					API_ENDPOINTS.exhangePrice,
					{
						price,
						merchantId: merchant.merchantId,
						orderType: isBuy ? 'BUY' : 'SELL',
					},
					{
						headers: { Authorization: `Bearer ${token}` },
					},
				);
				if (!exchangePriceChane.data.priceChanged) {
					const result = await axios.post(API_ENDPOINTS.openOrder, openOrderData, {
						headers: { Authorization: `Bearer ${token}` },
					});

					if (result.status === 201) {
						setLoading(false);
						navigate(`/order-life-cycle?id=${result.data.orderNumber}`);
					}
				} else {
					setShowModal(true);
					setPrice(exchangePriceChane.data.price);
					setRefersh(!refersh);
				}
			} catch (error) {
				console.error(error);
			} finally {
				setLoading(false);
			}
		}
	};
	const themeColor = configurationState.find((item) => item.configKey === 'theme_color')?.value || '';
	return (
		<>
			<Modal
				width={800}
				open={true}
				footer={null}
				closable={true}
				onCancel={closeModal}
				className='merchant-buy-sell-modal'
				closeIcon={
					<CloseOutlined
						className='close-modal-icon '
						onClick={closeModal}
						dir={getUserLanguageInCookie() === 'ar' ? 'rtl' : 'ltr'}
					/>
				}
			>
				<div className='modal-container'>
					<div
						style={{ backgroundColor: themeColor }}
						className={getUserLanguageInCookie() === 'ar' ? 'model-info-ar' : 'model-info'}
					>
						<div className='merchant-info'>
							<div
								className={`modal-merchant-initials ${
									getUserLanguageInCookie() === 'ar' ? 'margin-left-2' : 'margin-right-2'
								}`}
							>
								{getInitials(merchant.fullName)}
							</div>
							<div className='w-[90%] break-words'>
								<p className='merchant-name'>{merchant.fullName}</p>
								<p className='merchant-order'>
									{merchant.ordersCompleted} {t<string>('orders')}
									<span className='inline-block mx-[3px]' />
									<span className='vr' />
									{(
										(Number(merchant?.ordersCompleted) /
											(merchant?.totalOrders === null || merchant?.totalOrders === 0
												? 1
												: Number(merchant?.totalOrders))) *
										100
									)
										.toFixed()
										?.toLocaleString()}
								</p>
								<div className='flex merchant-order'>
									{merchant.totalReviews} {t<string>('reviews')}
									<span className='vr' />
									<p className={`rating ${merchant.rating < REVIEW_PERCENTAGE ? 'text-[#F6465D]' : 'text-[#33A867]'}`}>
										{merchant.rating < REVIEW_PERCENTAGE ? (
											<DislikeFilled className='rating-icon' />
										) : (
											<LikeFilled className='rating-icon text-[#33A867]' />
										)}
										{merchant.rating}%
									</p>
								</div>
							</div>
						</div>
						<div className='order-info'>
							<div className='order-info-item'>
								<span>{t<string>('price')}</span>
								<span id='merchant-buy-sell-dialog-price'>
									{price?.toLocaleString()} {merchant.currencyCode}
								</span>
							</div>
							<div className='order-info-item'>
								<span>{t<string>('available')}</span>
								<span id='merchant-buy-sell-dialog-available'>
									{merchant.available?.toLocaleString()} {merchant.exchangeBaseCurrency}
								</span>
							</div>
							<div className='order-info-item'>
								<span>{t<string>('limit')}</span>
								<span id='merchant-buy-sell-dialog-limit'>
									{merchant.min?.toLocaleString()}-{merchant.max?.toLocaleString()} {merchant.currencyCode}
								</span>
							</div>
						</div>
						<div className='hr' />
						<div className='terms-and-conditions'>
							<p className='tac-heading '>
								{isBuy ? t<string>('termsAndConditionsBuyers') : t<string>('termsAndConditionsSellers')}
							</p>
							<p style={{ color: '#fff', maxHeight: '300px', overflowY: 'hidden', overflowWrap: 'break-word' }}>
								{merchant.termsNote}
							</p>
						</div>
					</div>
					<div className='form'>
						<p className='unit-price'>
							{t<string>('unitPrice')}:{' '}
							<span id='merchant-buy-sell-dialog-unit-price'>
								{price} {merchant.currencyCode}
							</span>
						</p>
						<p className='m-0 p-0'>{isBuy ? t<string>('youPay') : t<string>('youSale')}</p>
						<label className='you-buy-or-sell my-1' dir='ltr'>
							<div className='you-buy-or-sell-input-container '>
								<input
									id='merchant-buy-sell-dialog-amount-input'
									value={amount as string}
									type='number'
									onChange={(e) => onAmountChange(e.target.value)}
									placeholder='0.00'
									className='m-0 h-9'
									min={0}
									step={0.01}
								/>
							</div>
							<div className='you-buy-or-sell-icon'>
								<span>{isBuy ? merchant.currencyCode : merchant.exchangeBaseCurrency}</span>
							</div>
						</label>
						<div className='text-[11px] mb-2'>
							<span>{t<string>('WALLET_BALANCE')}</span>: <b>{`${wallet?.balance} ${wallet?.currency}`}</b>
							<span className='h-[12px] w-[1px] inline-block mx-1 bg-black mb-[-2px]' />
							<span>{t<string>('limit')}</span>:{' '}
							<b>
								{isBuy
									? `${wallet.buyMin?.toLocaleString()} - ${wallet.buyMax?.toLocaleString()}`
									: `${wallet.sellMin?.toLocaleString()} - ${wallet.sellMax?.toLocaleString()}`}{' '}
								{wallet.currency}
							</b>
						</div>
						{youPayMessageError && isAmountTouched ? (
							<div className='text-[12px] text-red-500'>{youPayMessageError} </div>
						) : null}
						<p className='m-0 p-0'>{t<string>('youReceive')}</p>
						<label className='you-buy-or-sell disabled' dir='ltr'>
							<div className='you-buy-or-sell-input-container'>
								<input
									id='merchant-buy-sell-dialog-calc-amount-input'
									value={calculatedAmount}
									type='number'
									placeholder='0.00'
									className='m-0 h-9'
									disabled
									style={{ color: 'gray' }}
								/>
							</div>
							<div className='you-buy-or-sell-icon'>
								<span>{isBuy ? merchant.exchangeBaseCurrency : merchant.currencyCode}</span>
							</div>
						</label>
						{youRecieveMessageError && isAmountTouched ? (
							<div className='text-[12px] text-red-500'>{youRecieveMessageError} </div>
						) : null}
						<SelectPaymentMethods
							id='merchant-buy-sell-dialog-payment-methods-dropdown'
							methods={offerPaymentMethods}
							selectedMethod={selectedMethod}
							setSelectedMethod={setSelectedMethod}
						/>
						<div className='payment-note'>
							<p>{t<string>('note')}</p>
							<textarea
								id='merchant-buy-sell-dialog-client-note-input'
								rows={3}
								cols={50}
								placeholder={t<string>('pleaseWriteYourNote')}
								onChange={(e) => setClientNote(e.target.value)}
							/>
							{noteError && (
								<p>
									<span className='text-[red]'>{noteError}</span>
								</p>
							)}
						</div>
						<div className='note'>
							<ExclamationCircleOutlined className='flex justify-start items-start' />
							<p>{t<string>('certainPaymentMethodsMayHaveFees')}</p>
						</div>
						<Spin spinning={loading}>
							<button
								id='merchant-buy-sell-dialog-submit-button'
								className='main-btn'
								onClick={() => navigateToOrderLifeCyclePage()}
								disabled={isOfferDisabled || isTodayHoliday}
							>
								<Tooltip
									title={
										isTodayHoliday
											? t<string>('holidayDay')
											: mainState.userStatus === 'SUSPENDED'
											? t<string>('userSuspend')
											: canOpenOrder
											? undefined
											: t<string>('cantOpenOrderNows')
									}
								>
									{isBuy ? t<string>(isDev ? 'buy' : 'deposit') : t<string>(isDev ? 'sell' : 'withdrawal')}{' '}
									{merchant.currency}
								</Tooltip>
							</button>
						</Spin>
					</div>
				</div>
			</Modal>
			<PriceChangeModal showModal={showModal} setShowModal={setShowModal} />
		</>
	);
};

export default MerchantBuyAndSellModal;
