import { useEffect, useMemo, useRef, useState } from 'react';
import { OrderStateType } from '../../../../types/OrderStateType';
import { OrderFeedbackType } from '../../../../types/OrderFeedbackType';
import './ActionsCorner.scss';
import { getUserInfo } from '../../../../../../helpers/localStorageHandler';
import FeedbackForm from './FeedbackForm/FeedbackForm';
import { ActionType } from '../../../../types/ActionType';
import { Alert, Skeleton, Tooltip, notification } from 'antd';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { NoteConfirmationDialog } from './Dialogs/NoteConfirmation';
import { MoenyWaitingDialog } from './Dialogs/MoenyWating';
import { AcceptDialog } from './Dialogs/Accept';
import { RejectDialog } from './Dialogs/Reject';
import { CancelDialog } from './Dialogs/Cancel';
import { PaymentReceivedDialog } from './Dialogs/PaymentReceivedDialog';
import { PaymentCompletedDialog } from './Dialogs/PaymentCompletedDialog';
import { OrderType } from '../../../../types/OrderType';
import SubmittedFeedback from './SubmittedFeedback/SubmittedFeedback';

import { NoteConfirmationPayment } from './Dialogs/NoteConfirmationPayment';
import { CancelReason } from './Dialogs/CancelReason';
import { CheckCircleOutlined } from '@ant-design/icons';
import Stomp from 'stompjs';
import { useConfigurationContext } from 'store/configurationContext';
import { useHoliday } from 'store/HolidayContext';

type ActionsCornerProps = {
	orderState: OrderStateType;
	orderType: OrderType;
	actions: ActionType[];
	orderNumber: string;
	merchantId: string;
	feedback: OrderFeedbackType;
	userName: string;
	quantity: string;
	amount: string;
	canReview: boolean;
	isActionsDisabled: boolean;
	triggerAction: (action: ActionType, reasons?: string, otp?: string) => void;
	getOrderDetails: () => void;
	otpVlaid: { value: boolean };
	loading: boolean;
	stompClient: Stomp.Client | null;
};

function usePreviousStateValue(orderState: OrderStateType) {
	const ref = useRef<OrderStateType>();

	useEffect(() => {
		ref.current = orderState;
	});
	return ref.current;
}

function ActionsCorner({
	orderState,
	orderType,
	actions,
	orderNumber,
	merchantId,
	userName,
	quantity,
	amount,
	feedback,
	isActionsDisabled,
	canReview,
	triggerAction,
	getOrderDetails,
	otpVlaid,
	loading,
	stompClient,
}: ActionsCornerProps) {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { isTodayHoliday } = useHoliday();
	const { configurationState } = useConfigurationContext();

	// dialogs states
	const previousStateValue = usePreviousStateValue(orderState);
	const [showImportantNoteDialogMessage, setShowImportantNoteDialogMessage] = useState('');
	const [showMoenyWatingDialog, setShowMoenyWatingDialog] = useState(false);
	const [showAcceptDialog, setShowAcceptDialog] = useState(false);
	const [showRejectDialog, setShowRejectDialog] = useState(false);
	const [showCancelDialog, setShowCancelDialog] = useState(false);
	const [showPaymentReceivedDialog, setShowPaymentReceivedDialog] = useState(false);
	const [showPaymentCompletedDialog, setShowPaymentCompletedDialog] = useState(false);
	const [showImportantNotePayment, setshowImportantNotePayment] = useState('');
	const [showCancelReason, setShowCancelReason] = useState(false);
	const isSellOrder = useMemo(() => orderType === 'SELL', [orderType]);
	const isMerchant = useMemo(() => getUserInfo()?.isMerchant ?? false, []);
	const location = useLocation();
	const showInfo = useMemo(() => isMerchant && orderState === 'OPENED', [isMerchant, orderState]);
	const Company_Name = configurationState.find((item) => item.configKey === 'Company_Name')?.value || '';

	const feedbackStates: OrderStateType[] = useMemo(() => {
		if (Company_Name === 'INZO') return ['COMPLETED'];
		else return ['COMPLETED', 'CANCELLED', 'EXPIRED'];
	}, [Company_Name]);
	const showFeedback = useMemo(
		() => !isMerchant && canReview && feedbackStates.includes(orderState),
		[isMerchant, orderState, canReview],
	);

	// don't require a popup
	const handelAppeal = () => triggerAction('APPEAL');
	const handelFinishedSuccessfully = (otp: string) => triggerAction('FINISHED_SUCCESSFULLY', '', otp);

	// requires a popup
	const handelAccept = () => triggerAction('ACCEPT');
	const handelReject = () => triggerAction('REJECT');
	const handelCancelOrder = (reasons: string) => triggerAction('CANCEL', reasons);
	const handelPaymentReceived = (otp: string) =>
		triggerAction(
			(orderType === 'SELL' && isMerchant) || (orderType === 'BUY' && !isMerchant)
				? 'CONFIRM_BUYER_RECEIVED'
				: 'CONFIRM_SELLER_RECEIVED',
			'',
			otp,
		);
	const handelPaymentCompleted = () => triggerAction('CONFIRM_BUYER_TRANSFERRED');

	// requires a navigation
	const handelReorder = () => navigate(`/merchant?id=${merchantId}`);

	// popup on state change
	useEffect(() => {
		if (previousStateValue) {
			if (orderState === 'OPENED') {
				if (!isMerchant && !isSellOrder) {
					// Client + Buy => contact merchant
					setshowImportantNotePayment(t<string>('makeSureToContactMerchant', { companyName: Company_Name }));
				}
				if (!isMerchant && isSellOrder) {
					// Client + Sell => wait until merchant releases payment
					setShowImportantNoteDialogMessage(t<string>('waitUntilMerchantReleasesPayment'));
				}
				if (isMerchant && !isSellOrder) {
					// Merchant + Buy => wait until client transfers payment
					setShowImportantNoteDialogMessage(t<string>('waitUntilClientTransfersPayment'));
				}
				if (isMerchant && isSellOrder) {
					setshowImportantNotePayment(t<string>('makeSureToContactClient', { companyName: Company_Name }));
				}
			}
			if (orderState === 'TRANSFERRED') {
				if (!isMerchant && !isSellOrder) {
					// Client + Buy => wait until merchant confirms receiving payment
					setShowImportantNoteDialogMessage(t<string>('waitUntilMerchantConfirmsReceivingPayment'));
				}
				if (isMerchant && isSellOrder) {
					// Merchant + Sell => wait until client confirms receiving payment
					setShowImportantNoteDialogMessage(t<string>('waitUntilClientConfirmsReceivingPayment'));
				}
			}
			if (orderState === 'COMPLETED') {
				let message = isMerchant
					? t<string>('transferWasSentSuccessfullyMerchant', { amount: quantity, username: userName })
					: t<string>('transferWasSentSuccessfullyClient', { amount: quantity, username: userName });
				notification['success']({
					message: '',
					description: <div className='text-[green] font-bold ps-[15px]'>{message}</div>,
					style: { backgroundColor: '#d8e4dd', height: '75px', display: 'flex', alignItems: 'center' },
					icon: <CheckCircleOutlined className='w-[52px] h-[52px] text-[#33A867]' />,
				});
			}
		}
	}, [orderState]);

	useEffect(() => {
		setShowImportantNoteDialogMessage('');
		setShowMoenyWatingDialog(false);
		setShowAcceptDialog(false);
		setShowRejectDialog(false);
		setShowCancelDialog(false);
		setShowPaymentReceivedDialog(false);
		setShowPaymentCompletedDialog(false);
		setshowImportantNotePayment('');
		setShowCancelReason(false);
	}, [location.search]);

	return (
		<div className='actions-corner-container'>
			{orderState ? (
				<>
					<div className='buttons-container'>
						{actions.includes('CANCEL') ? (
							<Tooltip title={isTodayHoliday ? t<string>('holidayDay') : ''}>
								<button
									id='order-life-cycle_action-button_cancel-order'
									disabled={isTodayHoliday || isActionsDisabled || !stompClient}
									className='cancel-order'
									onClick={() => setShowCancelDialog(true)}
								>
									{t<string>('cancelOrder')}
								</button>
							</Tooltip>
						) : null}
						{actions.includes('CONFIRM_SELLER_RECEIVED') ? (
							<Tooltip title={isTodayHoliday ? t<string>('holidayDay') : ''}>
								<button
									id='order-life-cycle_action-button_payment-received'
									disabled={isTodayHoliday || isActionsDisabled || !stompClient}
									className='payment-received'
									onClick={() => setShowPaymentReceivedDialog(true)}
								>
									{t<string>('paymentReceived')}
								</button>
							</Tooltip>
						) : null}
						{actions.includes('CONFIRM_BUYER_TRANSFERRED') ? (
							<Tooltip title={isTodayHoliday ? t<string>('holidayDay') : ''}>
								<button
									id='order-life-cycle_action-button_confirm'
									disabled={isTodayHoliday || isActionsDisabled || !stompClient}
									className='payment-completed'
									onClick={() => setShowPaymentCompletedDialog(true)}
								>
									{t<string>('paymentCompleted')}
								</button>
							</Tooltip>
						) : null}
						{actions.includes('APPEAL') ? (
							<Tooltip title={isTodayHoliday ? t<string>('holidayDay') : ''}>
								<button
									id='order-life-cycle_action-button_appeal'
									disabled={isTodayHoliday || isActionsDisabled || !stompClient}
									className='appeal'
									onClick={() => handelAppeal()}
								>
									{t<string>('appeal')}
								</button>
							</Tooltip>
						) : null}
						{actions.includes('ACCEPT') ? (
							<Tooltip title={isTodayHoliday ? t<string>('holidayDay') : ''}>
								<button
									id='order-life-cycle_action-button_accept'
									disabled={isTodayHoliday || isActionsDisabled || !stompClient}
									className='accept'
									onClick={() => setShowAcceptDialog(true)}
								>
									{t<string>('accept')}
								</button>
							</Tooltip>
						) : null}
						{actions.includes('REJECT') ? (
							<Tooltip title={isTodayHoliday ? t<string>('holidayDay') : ''}>
								<button
									id='order-life-cycle_action-button_reject'
									disabled={isTodayHoliday || isActionsDisabled || !stompClient}
									className='reject'
									onClick={() => setShowRejectDialog(true)}
								>
									{t<string>('reject')}
								</button>
							</Tooltip>
						) : null}
						{actions.includes('REORDER') ? (
							<Tooltip title={isTodayHoliday ? t<string>('holidayDay') : ''}>
								<button
									id='order-life-cycle_action-button_reorder'
									disabled={isTodayHoliday || isActionsDisabled || !stompClient}
									className='reorder'
									onClick={() => handelReorder()}
								>
									{t<string>('reorder')}
								</button>
							</Tooltip>
						) : null}
						{actions.includes('FINISHED_SUCCESSFULLY') ? (
							<Tooltip title={isTodayHoliday ? t<string>('holidayDay') : ''}>
								<button
									id='order-life-cycle_action-button_finished'
									disabled={isTodayHoliday || isActionsDisabled || !stompClient}
									className='finished-successfully'
									onClick={() => setShowPaymentReceivedDialog(true)}
								>
									{t<string>('finishedSuccessfully')}
								</button>
							</Tooltip>
						) : null}
					</div>
					{feedback ? (
						<SubmittedFeedback feedback={feedback} />
					) : showFeedback ? (
						<FeedbackForm orderNumber={orderNumber} getOrderDetails={getOrderDetails} />
					) : null}
					{showInfo && !isMerchant ? (
						<Alert
							message={t<string>('completeYourPayment')}
							description={
								<ul>
									<li>{t<string>('note1')}</li>
									<li>{t<string>('note2')}</li>
								</ul>
							}
							type='info'
							showIcon
						/>
					) : null}
				</>
			) : (
				<Skeleton active paragraph={{ rows: 1 }} />
			)}
			<NoteConfirmationPayment
				showImportantNotePayment={showImportantNotePayment}
				setShowshowImportantNotePayment={setshowImportantNotePayment}
			/>
			<NoteConfirmationDialog
				showImportantNoteDialogMessage={showImportantNoteDialogMessage}
				setShowImportantNoteDialogMessage={setShowImportantNoteDialogMessage}
			/>
			<MoenyWaitingDialog
				showMoenyWatingDialog={showMoenyWatingDialog}
				setShowMoenyWatingDialog={setShowMoenyWatingDialog}
			/>
			<AcceptDialog
				showAcceptDialog={showAcceptDialog}
				setShowAcceptDialog={setShowAcceptDialog}
				handelAccept={handelAccept}
				loading={loading}
			/>
			<RejectDialog
				showRejectDialog={showRejectDialog}
				setShowRejectDialog={setShowRejectDialog}
				handelReject={handelReject}
				loading={loading}
			/>
			<CancelDialog
				showCancelDialog={showCancelDialog}
				setShowCancelDialog={setShowCancelDialog}
				setShowCancelReason={setShowCancelReason}
			/>
			<PaymentReceivedDialog
				showPaymentReceivedDialog={showPaymentReceivedDialog}
				setShowPaymentReceivedDialog={setShowPaymentReceivedDialog}
				handelPaymentReceived={handelPaymentReceived}
				handelFinishedSuccessfully={handelFinishedSuccessfully}
				isFinished={actions.includes('FINISHED_SUCCESSFULLY')}
				userName={userName}
				quantity={quantity}
				amount={amount}
				orderNumber={orderNumber}
				otpVlaid={otpVlaid}
				loading={loading}
			/>
			<PaymentCompletedDialog
				showPaymentCompletedDialog={showPaymentCompletedDialog}
				setShowPaymentCompletedDialog={setShowPaymentCompletedDialog}
				handelPaymentCompleted={handelPaymentCompleted}
				orderNumber={orderNumber}
				getOrderDetails={getOrderDetails}
				loading={loading}
			/>
			<CancelReason
				showCancelReason={showCancelReason}
				setShowCancelReason={setShowCancelReason}
				handelCancelOrder={handelCancelOrder}
				loading={loading}
			/>
		</div>
	);
}

export default ActionsCorner;
