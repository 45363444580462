import { Merchant, OfferType } from '../models/Merchants';
import { ColumnsType } from 'antd/es/table/interface';
import { TFunction } from 'i18next';
import { getInitials } from './getStringInitials';

import { Button, TableColumnType, Tooltip } from 'antd';
import { MinusCircleOutlined, LikeFilled, DislikeFilled, CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import { getUserLanguageInCookie } from './cookiesHandler';
import { Link } from 'react-router-dom';
import './merchantsTableColumns.scss';
import { PaymentMethod } from '../models/PaymentMethod';
import { getUserInfo } from './localStorageHandler';
import { handleSortClick } from './sorterColumn';

const REVIEW_PERCENTAGE = 80;
export const getMerchantsColumns = (
	paymentMethods: PaymentMethod[],
	isMerchant: boolean,
	offerType: OfferType.buy | OfferType.sell,
	t: TFunction<'translation', undefined, 'translation'>,
	sortBy: string,
	setSortBy: (sortValue: string) => void,
	setMerchantID: (merchantId: number) => void,
	setShowModal: (value: boolean) => void,
	userStatus: string,
	isMobile: boolean,
	isTodayHoliday: boolean,
) => {
	const isRightToLeft = getUserLanguageInCookie() === 'ar';
	const isDev = window.location.hostname.includes('devb');
	const getOfferType = (value: string) => {
		if (isDev) {
			return value?.toLocaleLowerCase() === OfferType.buy ? t<string>('buy') : t<string>('sell');
		} else {
			return value?.toLocaleLowerCase() === OfferType.buy ? t<string>('deposit') : t<string>('withdrawal');
		}
	};

	const columns: ColumnsType<Merchant> = [
		{
			title: <div className='text-[#000D1D99] text-sm '>{t<string>('merchantProfile')}</div>,
			dataIndex: 'fullName',
			ellipsis: true,
			responsive: ['lg'],
			align: isRightToLeft ? 'right' : 'left',
			className: 'merchantTableProfile',
			render: (value, row) => {
				const isNotGreatRating = row.rating < REVIEW_PERCENTAGE;
				return (
					<div className='flex'>
						<div
							className={`merchant-initials font-bold  status ${row.online ? 'online' : 'offline'} ${
								isRightToLeft ? 'ml-[6px]' : 'mr-[6px]'
							}`}
						>
							{getInitials(value)}
						</div>
						<div className='whitespace-nowrap	 text-ellipsis	overflow-hidden'>
							<Link
								className='text-lightBlue text-[13px] font-medium mb-1 underline'
								to={`/merchant?id=${row.merchantId}`}
							>
								{value}
							</Link>
							<p className='text-xs text-[#01081E99] flex items-center m-1'>
								{row.ordersCompleted} {t<string>('orders')}
								<span className='inline-block mx-1 w-[1px] h-[10px] bg-[#737E8D40]' />
								{(
									(Number(row?.ordersCompleted) /
										(row?.totalOrders === null || row?.totalOrders === 0 ? 1 : Number(row?.totalOrders))) *
									100
								).toFixed()}
								% {t<string>('completionRate')}
							</p>
							<p className='text-xs text-[#01081E99] flex items-center m-1'>
								{row.totalReviews} {t<string>('reviews')}
								<span className='inline-block mx-2 w-[1px] h-[10px] bg-[#737E8D40]' />
								<span className={`text-xs flex items-center ${isNotGreatRating ? 'text-[#F6465D]' : 'text-[#33A867]'}`}>
									{isNotGreatRating ? (
										<DislikeFilled
											className={`h-[13px] w-[13px] text-[#F6465D] ${isRightToLeft ? 'ml-[6px]' : 'mr-[6px]'}`}
										/>
									) : (
										<LikeFilled
											className={`h-[13px] w-[13px] text-[#33A867] ${isRightToLeft ? 'ml-[6px]' : 'mr-[6px]'}`}
										/>
									)}
									{row.rating}%
								</span>
							</p>
						</div>
					</div>
				);
			},
		},
		{
			title: <div className='text-[#000D1D99] text-sm '>{t<string>('price')}</div>,
			dataIndex: 'rate',
			key: 'rate',
			responsive: ['lg'],
			align: isRightToLeft ? 'right' : 'left',
			render: (value, row) => {
				return (
					<div className='text-[16px] font-medium text-lightBlue flex items-center max-w-[150px] min-w-[150px]'>
						{value} {row.currencyCode}
					</div>
				);
			},
		},
		{
			title: <div className='text-[#000D1D99] text-sm'>{t<string>('countryCurrency')}</div>,
			dataIndex: 'country',
			key: 'country',
			render: (_: string, row: Merchant) => (
				<div className='text-[16px] font-medium text-lightBlue flex items-center'>
					{row.countryCode}/{row.currencyCode}
				</div>
			),
			responsive: ['lg'],
			align: isRightToLeft ? 'right' : 'left',
			ellipsis: true,
		},
		{
			title: <div className='text-[#000D1D99] text-sm'>{t<string>('availableLimit')}</div>,
			dataIndex: 'available',
			key: 'available',
			responsive: ['lg'],
			align: isRightToLeft ? 'right' : 'left',
			render: (value, row) => {
				return (
					<>
						<div className='flex items-center h-6'>
							<span className='text-xs text-[#00142A66] '>{t<string>('available')}</span>
							<span className='text-xs text-lightBlue mx-2'>{value ?? '-'}</span>
							<span className='text-xs text-[#00142A66] '>{row.exchangeBaseCurrency}</span>
						</div>
						<div className='flex items-center h-6'>
							<span className='text-xs text-[#00142A66] '>{t<string>('limit')}</span>
							<span className='text-xs text-lightBlue mx-2'>
								{row.min} ~ {row.max}
							</span>
							<span className='text-xs text-[#00142A66]'>{row.currencyCode}</span>
						</div>
					</>
				);
			},
		},
		{
			title: <div className=' text-[#000D1D99] text-sm'>{t<string>('paymentMethods')}</div>,
			dataIndex: 'paymentMethods',
			key: 'paymentMethods',
			render: (_: string, row: Merchant) => {
				const methods = paymentMethods.filter((method) => (row?.paymentMethods ?? []).includes(method.methodId));

				return (
					<div className='flex flex-wrap gap-1'>
						{methods?.map((method, index) => (
							<span
								style={{ color: method.color, background: method.bgColor }}
								className={`text-[10px] py-1 px-1 rounded-sm`}
								key={index}
							>
								{method.methodName}
							</span>
						))}
					</div>
				);
			},
			responsive: ['lg'],
			align: isRightToLeft ? 'right' : 'left',
		},
		{
			title: (
				<div className=' text-[#000D1D99] text-sm flex'>
					{t<string>('trade')}
					<span className='bg-[#00B05014] rounded-sm mx-2 text-[10px] font-medium text-[#0077FF] whitespace-nowrap'>
						0 <span className='mx-1'>{t<string>('fees')}</span>
					</span>
					<div className='flex flex-col mx-2 text-xs'>
						<CaretUpOutlined
							onClick={() => setSortBy(handleSortClick(sortBy, 'activeBuyer-asc'))}
							style={{ color: sortBy === 'activeBuyer-asc' ? 'black' : 'gray' }}
						/>
						<CaretDownOutlined
							onClick={() => setSortBy(handleSortClick(sortBy, 'activeBuyer-desc'))}
							style={{ color: sortBy === 'activeBuyer-desc' ? 'black' : 'gray' }}
						/>
					</div>
				</div>
			),
			render: (_: string, row: Merchant, index) => {
				return isMerchant ? (
					<Tooltip placement='top' title={t<string>('onlyClientsCanStartOrders')}>
						<MinusCircleOutlined />
					</Tooltip>
				) : userStatus === 'SUSPENDED' ? (
					<Tooltip placement='top' title={t<string>('userSuspended')}>
						<MinusCircleOutlined />
					</Tooltip>
				) : (
					<Tooltip
						placement='top'
						title={
							isTodayHoliday
								? t<string>('holidayDay')
								: row.limitReached
								? t<string>('limitReached')
								: row.isCoolDown
								? t<string>('restricted')
								: ''
						}
					>
						<Button
							id={`merchants_offer_button_${index}`}
							onClick={() => {
								setMerchantID(row.merchantId);
								setShowModal(true);
							}}
							disabled={
								isTodayHoliday || row.limitReached || row.isCoolDown || getUserInfo()?.countryCode !== row.countryCode
							}
							className={`bg-transparent ${
								offerType === OfferType.buy
									? 'text-[#33A867] border border-[#33A867]'
									: 'text-[#F6465D] border border-[#F6465D]'
							}`}
						>
							{getOfferType(offerType)}
						</Button>
					</Tooltip>
				);
			},
			responsive: ['lg'],
			align: isRightToLeft ? 'right' : 'left',
		},
		...(isMobile
			? [
					{
						align: isRightToLeft ? 'right' : 'left',
						dataIndex: 'record',
						className: 'small-screen-row merchantTableProfile',
						render: (value, row, index) => {
							const isNotGreatRating = row.rating < REVIEW_PERCENTAGE;
							const methods = paymentMethods.filter((method) => (row?.paymentMethods ?? []).includes(method.methodId));

							return (
								<div className='flex pb-0 justify-between'>
									<div className='flex-col'>
										<div className='flex flex-row'>
											<div
												className={`merchant-initials font-bold status ${row.online ? 'online' : 'offline'} ${
													isRightToLeft ? 'ml-[6px]' : 'mr-[6px]'
												}`}
											>
												{getInitials(row.fullName)}
											</div>
											<Link
												className='text-lightBlue text-sm font-medium mx-1 underline'
												to={`/merchant?id=${row.merchantId}`}
											>
												{row.fullName}
											</Link>
										</div>
										<div>
											<p className='text-xs text-[#01081E99] flex items-center mb-1 mt-[-10px] ms-[38px]'>
												{row.ordersCompleted} {t<string>('orders')}{' '}
												<span className='inline-block mx-1 w-[1px] h-[10px] bg-[#737E8D40]' /> {row.totalReviews}{' '}
												{t<string>('reviews')}
											</p>
											<p className='text-xs text-[#01081E99] flex items-center mb-1 mt-1 ms-[38px]'>
												{(
													(Number(row?.ordersCompleted) /
														(row?.totalOrders === null || row?.totalOrders === 0 ? 1 : Number(row?.totalOrders))) *
													100
												).toFixed()}
												% {t<string>('completionRate')}
											</p>
										</div>
										<div className='flex items-center h-6 py-1'>
											<span className='text-xs text-[#00142A66] '>{t<string>('countryCurrency')}</span>
											<span className='text-xs text-lightBlue mx-2'>
												{row.countryCode}/{row.currencyCode}
											</span>
										</div>
										<div className='flex items-center h-6 py-1'>
											<span className='text-xs text-[#00142A66] '>{t<string>('limit')}</span>
											<span className='text-xs text-lightBlue mx-2'>
												{row.min} ~ {row.max}
											</span>
											<span className='text-xs text-[#00142A66]'>{row.currencyCode}</span>
										</div>
										<div className='flex flex-wrap gap-1 py-1 max-w-[150px]'>
											{methods?.map((method, index) => (
												<span
													style={{ color: method.color, background: method.bgColor }}
													className={`text-[10px] py-1 px-1 rounded-sm`}
													key={index}
												>
													{method.methodName}
												</span>
											))}
										</div>
									</div>
									<div className='flex flex-col items-end'>
										<div className='button-container'>
											<p
												className={`text-xs flex items-center ${
													isNotGreatRating ? 'text-[#F6465D]' : 'text-[#33A867]'
												}`}
											>
												{row.rating}%
												{isNotGreatRating ? (
													<DislikeFilled
														className={`h-[13px] w-[13px] text-[#F6465D] ${isRightToLeft ? 'ml-[6px]' : 'mr-[6px]'}`}
													/>
												) : (
													<LikeFilled
														className={`h-[13px] w-[13px] text-[#33A867] ${isRightToLeft ? 'ml-[6px]' : 'mr-[6px]'}`}
													/>
												)}
											</p>
										</div>
										<div className='flex items-center h-6 mt-4'>
											<div className='text-xs text-[#00142A66]'>
												{t<string>('price')}
												<span className='inline-block mx-1 text-[#000] text-[13px]'>
													{row.rate} {row.currencyCode}
												</span>
											</div>
										</div>
										<div
											className='flex items-center m-2'
											style={{ flexDirection: row.available?.toString().length > 8 ? 'column' : 'row' }}
										>
											<span className='text-xs text-[#00142A66]'>{t<string>('available')}</span>
											<div className='flex items-center'>
												<span className='text-xs text-lightBlue mx-1'>{row.available}</span>
												<span className='text-xs text-[#00142A66]'>{row.exchangeBaseCurrency}</span>
											</div>
										</div>
										<div className='button-container mt-2'>
											{isMerchant ? (
												<Tooltip placement='top' title={'Only clients can start the order'}>
													<MinusCircleOutlined />
												</Tooltip>
											) : userStatus === 'SUSPENDED' ? (
												<Tooltip placement='top' title={t<string>('userSuspended')}>
													<MinusCircleOutlined />
												</Tooltip>
											) : (
												<Tooltip
													placement='top'
													title={
														isTodayHoliday
															? t<string>('holidayDay')
															: row.limitReached
															? t<string>('limitReached')
															: row.isCoolDown
															? t<string>('restricted')
															: ''
													}
												>
													<Button
														id={`merchants_offer_button_xs_${index}`}
														onClick={() => {
															setMerchantID(row.merchantId);
															setShowModal(true);
														}}
														disabled={isTodayHoliday || row.limitReached || row.isCoolDown}
														className={`bg-transparent ${
															offerType === OfferType.buy
																? 'text-[#33A867] border border-[#33A867]'
																: 'text-[#F6465D] border border-[#F6465D]'
														}`}
													>
														{getOfferType(offerType)}
													</Button>
												</Tooltip>
											)}
										</div>
									</div>
								</div>
							);
						},
					} as TableColumnType<Merchant>,
			  ]
			: []),
	];

	return columns;
};
