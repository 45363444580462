import { useEffect, useState } from 'react';
import axios from 'axios';
import './Annoncements.scss';
import { useTranslation } from 'react-i18next';
import { API_ENDPOINTS } from 'assets/api/endpoints';
import dayjs from 'dayjs';
import { useHoliday } from 'store/HolidayContext';
import { useConfigurationContext } from 'store/configurationContext';
import { Grid } from 'antd';
const DATE_TIME_FORMAT = 'MMM D, YYYY h:mm A';

export default function AnnoncemetBanner() {
	const { comingHolidays } = useHoliday();
	const { xs } = Grid.useBreakpoint();
	const { configurationState } = useConfigurationContext();

	const [annoncements, setAnnocements] = useState<string[]>([]);
	const { t } = useTranslation();
	useEffect(() => {
		let Holidays: string[] = [];
		axios.get(API_ENDPOINTS.getAnnoncement).then((result) => {
			const annocementsArr = result.data.map((item: any) =>
				t<string>('annoncement', {
					from: dayjs(item.startDate).format(DATE_TIME_FORMAT),
					to: dayjs(item.endDate).format(DATE_TIME_FORMAT),
				}),
			);
			if (comingHolidays.length > 0) {
				Holidays = comingHolidays.map((item: any) => {
					if (item.type === 'W') {
						return t<string>('weekdayHoliday', {
							weekdays: item.weekDays.map((item: string) => t(item)).join(', '),
							name: item.name,
						});
					} else {
						return t<string>('dateRangeHoliday', { startDate: item.startDate, endDate: item.endDate, name: item.name });
					}
				});
			}
			setAnnocements([...annocementsArr, ...Holidays]);
		});
	}, [comingHolidays, t]);

	if (!annoncements.length) {
		return null;
	}
	const themeColor = configurationState.find((item) => item.configKey === 'theme_color')?.value || '';
	const secondary_color = configurationState.find((item) => item.configKey === 'secondary_color')?.value || '';
	return (
		<div
			dir='ltr'
			id='annoncement-banner'
			style={{
				position: 'fixed',
				zIndex: 1000,
				width: '100%',
				top: '0px',
				height: '35px',
				boxShadow: 'white 0px 0px 5px',
				backgroundColor: themeColor,
				opacity: '.8',
				color: secondary_color,
			}}
		>
			<div className={annoncements.length === 1 && !xs ? 'no-marquee' : 'marquee'}>
				<p>
					{annoncements.map((announcement, index) => (
						<>
							<span key={index} className='marquee-item mx-5'>
								{announcement}
							</span>
							{index !== annoncements.length - 1 && <span>|</span>}
						</>
					))}
				</p>
			</div>
		</div>
	);
}
