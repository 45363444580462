import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import React, { useLayoutEffect } from 'react';
import SignIn from '../pages/SignIn/SignIn';
import ProtectedRoutes from './ProtectedRoutes';
import NotFound from '../pages/NotFound/NotFound';
import Merchants from '../pages/Merchants/Merchants';
import MyOrders from '../pages/MyOrders/MyOrders';
import SingleMerchant from '../pages/SingleMerchant/SingleMerchant';
import MyOffers from '../pages/MyOffers/MyOffers';
import OrderLifeCycle from '../pages/OrderLifeCycle/OrderLifeCycle';
import Navbar from '../components/Navbar/Navbar';
import Footer from '../components/Footer/Footer';
import ContactSupport from '../pages/ContactSupport/ContactSupport';
import MyAccounts from '../pages/MyAccounts/MyAccounts';
import MainWrapper from './MainWrapper';
import Notifications from '../pages/Notifications/Notifications';
import { NotificationProvider } from 'components/NotificationContextProvider';

import { Wallet } from '../pages/Wallet';
import { WalletAccounts } from 'pages/WalletAccounts';
import Redirect from 'pages/Redirect';
import { getUserInfo } from 'helpers/localStorageHandler';
import { authChannel } from 'components/Navbar/NavBarIcons';

function Views() {
	const location = useLocation();
	const user = getUserInfo();

	useLayoutEffect(() => {
		window.scrollTo(0, 0);
	}, [location.pathname]);

	// Listen for login/logout events from other tabs
	authChannel.onmessage = (event) => {
		if (event.data.type === 'LOGOUT' || event.data.type === 'LOGIN') {
			window.location.reload();
		}
	};
	return (
		<MainWrapper>
			<>
				<NotificationProvider>
					<Navbar />
					<Routes>
						{/* Visible for all users */}
						<Route path='/redirect' element={<Redirect />} />
						<Route path='/sign-in' element={user?.email ? <Navigate to='/merchants' replace /> : <SignIn />} />
						{/* <Route path='/payment-status' element={<PaymentStatus />} /> */}
						<Route path='/' element={<Navigate to='/merchants' />} />
						<Route path='/contact-support' element={<ContactSupport />} />
						{/* Visible for all authorized users */}
						<Route element={<ProtectedRoutes />}>
							<Route path='/order-life-cycle' element={<OrderLifeCycle />} />
							<Route path='/my-orders' element={<MyOrders />} />
							<Route path='/merchants' element={<Merchants />} />
							<Route path='/notifications' element={<Notifications />} />
							<Route path='/merchant' element={<SingleMerchant />} />
							<Route path='/my-offers' element={<MyOffers />} />
							<Route path='/my-accounts' element={<MyAccounts />} />
							<Route path='/wallet' element={<Wallet />} />
							<Route path='/wallet-accounts' element={<WalletAccounts />} />
							{/* <Route path='/account-verification' element={<MerchantVerification />} /> */}
							<Route path='*' element={<NotFound />} />
						</Route>
					</Routes>
				</NotificationProvider>
				<Footer />
			</>
		</MainWrapper>
	);
}

export default React.memo(Views);
