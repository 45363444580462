import { ExclamationCircleFilled, InfoCircleOutlined } from '@ant-design/icons';
import { Checkbox, Input, Modal, notification, Spin } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { API_ENDPOINTS } from 'assets/api/endpoints';
import { getUserInfo } from 'helpers/localStorageHandler';
import { getUserLanguageInCookie } from 'helpers/cookiesHandler';
import { convertMilliseconds } from 'helpers/getRemainingTime';

const numberFormatter = (num: number) => {
	return `${num > 9 ? '' : '0'}${num}`;
};

const OTP_LENGTH = 6;
export function OTPTransactionModal({
	showOtpModal,
	setShowOtpModal,
	vervifyOtp,
	payload,
}: {
	showOtpModal: boolean;
	setShowOtpModal: (value: boolean) => void;
	vervifyOtp: (otp: string) => void;
	payload: {
		type: String;
		quantity: number;
		tradingPlatform: String | undefined;
		tradingAccount: String | undefined;
		currency: String | undefined;
	};
}) {
	const { t } = useTranslation();
	const [isChecked, setIsChecked] = useState(false);
	const [otp, setOtp] = useState(['', '', '', '', '', '']);
	const [minutes, setMinutes] = useState('0');
	const [seconds, setSeconds] = useState('0');
	const [expireTime, setExpireTime] = useState(0);
	const [currentCountdown, setCurrentCountdown] = useState(0);
	const [countdownWorker, setCountdownWorker] = useState<Worker | null>(null);
	const token = getUserInfo()?.token;

	const handleOtpChange = (index: number, value: string) => {
		const newOtp = [...otp];
		newOtp[index] = value;
		// Move to the next input field if a digit is entered
		if (value !== '' && index < 6) {
			const nextInput = document.getElementById(`otp-input-${index + 1}`) as HTMLInputElement | null;
			if (nextInput) {
				nextInput.focus();
			}
		}
		if (value === '') {
			const nextInput = document.getElementById(`otp-input-${index - 1}`) as HTMLInputElement | null;
			if (nextInput) {
				nextInput.focus();
			}
		}

		setOtp(newOtp);
	};

	const sendOtp = (resend?: boolean) => {
		axios
			.post(`${API_ENDPOINTS.getOtpGenerate}`, payload, {
				headers: { Authorization: `Bearer ${token}` },
			})
			.then((res) => {
				if (resend) {
					notification['success']({ message: '', description: t<string>('otpResendMessage') });
				}
				setExpireTime(res.data.remainingExpiryTime);
			});
	};

	useEffect(() => {
		setOtp(['', '', '', '', '', '']);
		if (showOtpModal) {
			sendOtp();
		}
	}, [showOtpModal]);

	useEffect(() => {
		let worker = new Worker('./countDownMillieSeconds.js');
		worker.postMessage({ type: 'init', countdown: expireTime | 0 });
		worker.onmessage = function (e) {
			if (e.data.type === 'countdown') {
				setCurrentCountdown(e.data.countdown);
			} else if (e.data.type === 'completed') {
				setShowOtpModal(false);
			}
		};

		setCountdownWorker(worker);
		return () => {
			worker.terminate();
		};
	}, [expireTime]);

	useEffect(() => {
		const { hours, minutes, seconds } = convertMilliseconds(currentCountdown);
		if (hours <= 0 && minutes <= 0 && seconds <= 0) {
			setShowOtpModal(false);
		}
		setMinutes(numberFormatter(Math.floor(minutes)));
		setSeconds(numberFormatter(seconds));
	}, [currentCountdown]);

	return (
		<Modal
			onCancel={() => {
				setShowOtpModal(false);
				setIsChecked(false);
			}}
			open={showOtpModal}
			footer={null}
			destroyOnClose
			title={
				<div className='flex align-baseline'>
					<ExclamationCircleFilled className='text-[#0f2147] mx-2' />
					<h4 className='m-0'>{payload.type === 'WITHDRAW' ? t('withdrawRequesttitle') : t('depositRequestTitle')}</h4>
				</div>
			}
		>
			<ul style={{ listStyleType: 'none', padding: '0px' }}>
				<li>
					<Checkbox
						checked={isChecked}
						onChange={(e) => setIsChecked(e.target.checked)}
						style={{ marginBottom: '20px' }}
					>
						{payload.type === 'WITHDRAW'
							? t<string>('WithdrawRequest', {
									amount: payload.quantity,
							  })
							: t<string>('depositWithdrawRequest', {
									amount: payload.quantity,
									type: t<string>('deposit'),
							  })}
					</Checkbox>
				</li>
			</ul>
			<p>{t<string>('enterYourOtp')}</p>
			<div className='mb-4 flex '>
				<div style={{ direction: 'ltr', marginBlockStart: '5px' }}>
					{otp.map((digit, index) => (
						<Input
							key={index}
							id={`otp-input-${index}`}
							value={digit}
							onChange={(e) => handleOtpChange(index, e.target.value)}
							maxLength={1}
							style={{ width: '2rem', marginRight: '1rem', textAlign: 'center' }}
							onPaste={(e) => {
								const copiedValue = e.clipboardData.getData('text/plain').split('');
								const otpValue =
									copiedValue.length > OTP_LENGTH
										? copiedValue.slice(0, OTP_LENGTH)
										: [...copiedValue, ...Array(OTP_LENGTH - copiedValue.length).fill('')];
								setOtp(otpValue);
							}}
						/>
					))}
				</div>
				<div className='m-auto'>
					{t<string>('expireIn')}:{' '}
					{getUserLanguageInCookie() === 'ar' ? (
						<>
							<span>{seconds}</span> : <span>{minutes}</span>{' '}
						</>
					) : (
						<>
							<span>{minutes}:</span> <span>{seconds}</span>
						</>
					)}
				</div>
			</div>
			<div>
				<p>
					{t<string>('didNotreceiveOtp')}
					<span className='text-[#0184ff] underline cursor-pointer' onClick={() => sendOtp(true)}>
						{t<string>('resend')}
					</span>
				</p>
			</div>
			<button
				disabled={!isChecked || otp.some((value) => value === '')}
				className='form-btn'
				style={{ cursor: 'pointer', margin: '0 auto', maxWidth: '100px', height: '36px', fontWeight: 400 }}
				onClick={() => vervifyOtp(otp.join(''))}
			>
				{t<string>('confirm')}
			</button>
		</Modal>
	);
}
