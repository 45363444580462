import Rocket from './rocket.png';

import './index.css';
import { useEffect } from 'react';
import axios from 'axios';
import { API_ENDPOINTS } from 'assets/api/endpoints';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMainContext } from 'store/MainContext';
import { deleteUserInfo, setUserInfo } from 'helpers/localStorageHandler';
import { resetMainState } from 'assets/config/initial-main-state';
import { Configuration, useConfigurationContext } from 'store/configurationContext';
import { sessionExpiredUrl as defaultsSessionExpiredUrl, fullLogo as defaultFullLogo } from 'constant/fallback-config';
import { useTranslation } from 'react-i18next';
import { getUserLanguageInCookie } from 'helpers/cookiesHandler';
import { authChannel } from 'components/Navbar/NavBarIcons';

export default function Redirect() {
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const redirectToken = queryParams.get('redirectToken') || '';
	const { mainState, setMainState } = useMainContext();
	const { t } = useTranslation();
	const { configurationState } = useConfigurationContext();
	const navigate = useNavigate();
	useEffect(() => {
		if (redirectToken) {
			axios
				.post(API_ENDPOINTS.redirectLogin, {
					redirectToken: redirectToken,
				})
				.then((res) => {
					const userData = res.data;
					authChannel.postMessage({ type: 'LOGIN', payload: userData });
					setMainState({
						...mainState,
						email: userData?.email,
						fullName: userData?.fullName,
						isLoggedIn: true,
						isMerchant: userData?.isMerchant,
						token: userData?.token,
						countryCode: userData?.countryCode,
						language: userData?.language,
					});
					setUserInfo({ ...res.data, language: userData?.language });
					navigate('/merchants');
				})
				.catch(async (err) => {
					clearDate();
				});
		} else {
			redirectToSessionExpiredUrl();
		}
	}, [redirectToken]);

	const redirectToSessionExpiredUrl = () => {
		const sessionExpiredUrl =
			configurationState.find((item: Configuration) => item.configKey === 'session_expired_url')?.value || '';
		clearDate();
		if (sessionExpiredUrl) {
			window.location.replace(`${sessionExpiredUrl}`);
		} else {
			navigate(`/sign-in`);
		}
	};
	const clearDate = () => {
		deleteUserInfo();
		setMainState(resetMainState);
	};
	const fullLogo = configurationState.find((item: Configuration) => item.configKey === 'full_logo')?.value || '';
	const themeColor = configurationState.find((item) => item.configKey === 'theme_color')?.value || '';
	return (
		<div
			className='text-white top-[60px] xss:h-[87%] md:h-auto'
			style={{
				boxShadow: 'white 0px 0px 5px',
				backgroundColor: themeColor,
				opacity: '.9',
				minHeight: 'calc(100vh - 120px)',
				position: 'relative',
			}}
		>
			<div className='h-full flex w-full xss:flex-col sm:flex-row justify-between items-center'>
				<div className='flex-grow h-full'>
					<div className='h-full flex flex-col gap-2 text-center items-center justify-center '>
						<img src={fullLogo} alt='' className='xss:h-12 sm:h-16' />
						<h1 className='text-3xl font-bold'>{t('holdon')}</h1>
						{redirectToken ? <div>{t('youAreBeignRedirect')}</div> : <div>{t('youAreBeingRedirectBack')}</div>}
						<div className='progress-container'>
							<div className='progress-bar'></div>
						</div>
					</div>
				</div>
				<div className='flex justify-end h-[100%]'>
					<img className='h-full xss:h-[500px] sm:h-[835px]' src={Rocket} alt='Background image' />
				</div>
			</div>
		</div>
	);
}
