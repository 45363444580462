import React, { useRef, useState } from 'react';
import { Button, Grid, Popover, Tooltip } from 'antd';
import { FileImageOutlined, FileTextOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

type FileUploaderProps = {
	style: React.CSSProperties;
	disabled: boolean;
	isTodayHoliday: boolean;
	handleUploadFileClick: (event: any, type: 'IMAGE' | 'FILE') => void;
};
const FileUploader = ({ style, disabled, isTodayHoliday, handleUploadFileClick }: FileUploaderProps) => {
	const { t } = useTranslation();
	const { xs } = Grid.useBreakpoint();
	const [popoverVisible, setPopoverVisible] = useState(false);
	const fileInputRef = useRef<HTMLInputElement | null>(null);
	const [acceptType, setAcceptType] = useState('');
	const [type, setType] = useState<'IMAGE' | 'FILE'>();

	const handleSelect = (type: 'IMAGE' | 'FILE') => {
		const newAcceptType = type === 'IMAGE' ? 'image/*' : '.pdf,.doc,.docx';
		setType(type);
		// Reset input to ensure it opens with the right type
		if (fileInputRef.current) {
			fileInputRef.current.value = ''; // Reset the file input
		}

		setAcceptType(newAcceptType);
		setPopoverVisible(false);

		// Wait for state to update before triggering the file input
		setTimeout(() => {
			fileInputRef.current?.click();
		}, 0);
	};

	return (
		<div>
			<Popover
				content={
					<div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
						<Button
							className='flex items-center justify-start p-2'
							icon={<FileImageOutlined />}
							onClick={() => handleSelect('IMAGE')}
						>
							{t('Photos')}
						</Button>
						<Button
							className='flex items-center justify-start p-2'
							icon={<FileTextOutlined />}
							onClick={() => handleSelect('FILE')}
						>
							{t('Document')}
						</Button>
					</div>
				}
				trigger='click'
				open={disabled ? false : popoverVisible}
				onOpenChange={setPopoverVisible}
				placement='top' // Prevents repositioning issues
				overlayStyle={xs ? { position: 'fixed' } : {}} //
			>
				<Tooltip title={isTodayHoliday ? t<string>('holidayDay') : ''}>
					<Button className='shadow-none' type='primary' disabled={disabled}>
						<FileImageOutlined style={style} />
					</Button>
				</Tooltip>
			</Popover>

			{/* Hidden file input */}
			<input
				type='file'
				accept={acceptType}
				ref={(el) => (fileInputRef.current = el)}
				style={{ display: 'none' }}
				onChange={(e) => {
					if (e.target.files?.length && type) {
						handleUploadFileClick(e, type);
					}
				}}
			/>
		</div>
	);
};

export default FileUploader;
