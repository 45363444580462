import React from 'react';

export const initialConfiguration = [
	{
		configKey: '',
		value: '',
	},
];
export type Configuration = {
	configKey: string;
	value: string;
};
type Store = {
	configurationState: Configuration[];
	setConfigurationState: React.Dispatch<React.SetStateAction<Configuration[]>>;
};

export const ConfigurationContext = React.createContext<Store>({
	configurationState: {},
} as Store);

export const useConfigurationContext = () => React.useContext(ConfigurationContext);
