import { Grid, Table } from 'antd';
import { Wallet } from '../types/Wallet';
import { useEffect, useMemo, useState } from 'react';
import { columns } from '../columns';
import { useTranslation } from 'react-i18next';
import { TransactionReportModal } from '../modals/TransactionReportModal';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { API_ENDPOINTS } from 'assets/api/endpoints';
import { getUserInfo } from 'helpers/localStorageHandler';
import { StyledPagination } from 'components/table.style';
import { useConfigurationContext } from 'store/configurationContext';

type WalletTableProps = {
	data: Wallet[];
	loading: boolean;
	page: number;
	numberOfEntries: number;
	setPage: (page: number) => void;
	total: number;
	sortBy: string;
	setSortBy: (sortValue: string) => void;
};

const getOperation = (type: string) => {
	if (type.includes('P2P')) return 'P2P';
	if (type.includes('TRADING')) return 'TRADING_ACCOUNT';
	if (type.includes('ADMIN')) return 'ADMIN_OPERATION';
};

export const WalletTable = (props: WalletTableProps) => {
	const { lg } = Grid.useBreakpoint();
	const { configurationState } = useConfigurationContext();

	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);

	const [transactionReportModalOpen, setTransactionReportModalOpen] = useState(false);
	const [transactionReportData, setTransactionReportData] = useState<{ payload: any; title: string }>();
	const transactionNumber = queryParams.get('transactionNumber');
	const transactionType = queryParams.get('type');

	const token = getUserInfo()?.token;

	const { t } = useTranslation();

	const onView = (record: Wallet) => {
		getTransaction(record.transactionNumber, record.type);
	};

	useEffect(() => {
		if (transactionNumber && transactionType) {
			getTransaction(transactionNumber, transactionType);
		}
	}, [location.search]);

	const walletTableColumns = useMemo(
		() =>
			columns({
				isMobile: !lg,
				translate: t,
				onView,
				sortBy: props.sortBy,
				setSortBy: props.setSortBy,
			}),
		[lg, t, props.sortBy],
	);

	const getTransaction = (transactionNumber: string, type: string) => {
		const operation = getOperation(type) || 'P2P';
		axios
			.get(
				API_ENDPOINTS.operationReport
					.replace('%operation%', operation)
					.replace('%transactionNumber%', transactionNumber ?? ''),
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				},
			)
			.then((response) => {
				setTransactionReportData({
					payload: {
						...response?.data,
					},
					title: response?.data.type as unknown as string,
				});
				setTransactionReportModalOpen(true);
			})
			.catch(console.error);
	};

	const themeColor = configurationState.find((item) => item.configKey === 'theme_color')?.value || '';
	const secondary_color = configurationState.find((item) => item.configKey === 'secondary_color')?.value || '';
	return (
		<div>
			<TransactionReportModal
				open={transactionReportModalOpen}
				payload={transactionReportData?.payload}
				setOpen={setTransactionReportModalOpen}
				title={transactionReportData?.title}
			/>
			<StyledPagination bgColor={themeColor} color={secondary_color} lg={lg}>
				<Table
					columns={walletTableColumns}
					dataSource={props.data}
					loading={props.loading}
					pagination={{
						pageSize: props.numberOfEntries,
						onChange(page, pageSize) {
							props.setPage(page - 1);
						},
						total: props.total,
						current: props.page + 1,
					}}
					tableLayout='fixed'
					className={lg ? '' : 'hide-table-header'}
					rowKey='id'
				/>
			</StyledPagination>
		</div>
	);
};
