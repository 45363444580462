import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './Footer.scss';
import { useConfigurationContext } from 'store/configurationContext';
import { Grid, Tooltip } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { useMainContext } from 'store/MainContext';
import { useLocation } from 'react-router-dom';
const Footer = () => {
	const [liveChatHidden, setLiveChatHidden] = useState(false);
	const currentYear = React.useMemo(() => new Date().getFullYear(), []);
	const { xs } = Grid.useBreakpoint();
	const { t } = useTranslation();
	const location = useLocation();
	const { mainState } = useMainContext();
	const { configurationState } = useConfigurationContext();
	const isBanner = document.getElementById('annoncement-banner');
	const top = useMemo(() => {
		let top = '60px';
		if (!mainState.isLoggedIn) return (top = '50px');
		if (isBanner && xs) return (top = '80px');
		if (!isBanner && xs) return (top = '88px');
		return top;
	}, [isBanner, xs]);
	const Company_Name = configurationState.find((item) => item.configKey === 'Company_Name')?.value || '';
	const themeColor = configurationState.find((item) => item.configKey === 'theme_color')?.value;
	const secondary_color = configurationState.find((item) => item.configKey === 'secondary_color')?.value || '';

	const hideTodioChat = useMemo(() => {
		if (
			!mainState.isLoggedIn ||
			location.pathname.includes('order-life-cycle') ||
			location.pathname.includes('sign-in') ||
			location.pathname.includes('redirect') ||
			Company_Name.toLowerCase() !== 'inzo'
		)
			return true;
		else return false;
	}, [configurationState, location, mainState]);

	useEffect(() => {
		// Tidio is loaded globally on window.tidioChatApi
		if (window.tidioChatApi) {
			if (liveChatHidden || hideTodioChat) {
				window.tidioChatApi.hide();
			} else {
				window.tidioChatApi.show();
			}
		}
	}, [liveChatHidden, location, hideTodioChat]);

	return (
		<>
			<div
				className='footer-tail py-4'
				style={{
					backgroundColor: themeColor,
					color: secondary_color,
					height: '60px',
					position: 'relative',
					marginTop: top,
				}}
			>
				<div className='max-w-[1440px] mx-auto flex flex-col sm:flex-row items-center justify-between h-full'>
					<span className=' text-xs lg:mb-0'>
						© {currentYear}{' '}
						<a
							className=' text-xs no-underline'
							href={`https://webbats.com`}
							target='_blank'
							style={{ color: secondary_color }}
						>
							webbats
						</a>{' '}
						{t<string>('allRightsReserved')}
					</span>
				</div>
			</div>
			{!hideTodioChat && (
				<div
					className='fixed bottom-[60px] z-[9999]'
					style={{ right: liveChatHidden ? '1.5rem' : !xs ? '16.5rem' : '5rem' }}
				>
					{liveChatHidden ? (
						<Tooltip placement='left' title={t<string>('showLiveChat')}>
							<div className='w-8 h-8 rounded-full flex justify-center bg-lightBlue'>
								<LeftOutlined className='text-[20px] text-white' size={20} onClick={() => setLiveChatHidden(false)} />
							</div>
						</Tooltip>
					) : (
						<Tooltip placement='left' title={t<string>('hideLiveChat')}>
							<div className='w-8 h-8 rounded-full flex justify-center bg-lightBlue'>
								<RightOutlined className='text-[20px] text-white' onClick={() => setLiveChatHidden(true)} />
							</div>
						</Tooltip>
					)}
				</div>
			)}
		</>
	);
};

export default Footer;
