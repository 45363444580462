import { useMemo, useState } from 'react';
import { useCountriesContext } from '../../../../../store/CountriesContext';
import { useTranslation } from 'react-i18next';
import { OrderType } from '../../../types/OrderType';
import { getUserInfo } from '../../../../../helpers/localStorageHandler';
import { Skeleton, notification, Alert } from 'antd';
import './ResponsiveBoduDetails.scss';
import { CopyFilled, DownloadOutlined } from '@ant-design/icons';
import { getUserLanguageInCookie } from 'helpers/cookiesHandler';
import axios from 'axios';
import { API_ENDPOINTS } from 'assets/api/endpoints';
import { PhotoPreview } from './PaymentMethodDetails/PhotoPreview';
import { decodeBase64 } from './PaymentMethodDetails/PaymentMethodDetails';

type PaymentMethodDetailsProps = {
	paymentMehodId: number;
	accountNumber: string;
	amount: string;
	orderType: OrderType;
	unitPrice: string;
	quantity: string;
	note: string;
	paymentProof: boolean;
	paymentProofFileId: string;
	paymentProofFileLabel: string;
	orderNumber: string;
	accountGroup: string;
};

const CHAR_SIZE = 5;
function ResponsiveBodyDetails({
	paymentMehodId,
	accountNumber,
	amount,
	orderType,
	unitPrice,
	quantity,
	note,
	orderNumber,
	paymentProof,
	paymentProofFileId,
	paymentProofFileLabel,
	accountGroup,
}: PaymentMethodDetailsProps) {
	const { t } = useTranslation();
	const { countriesState } = useCountriesContext();
	const [showPreviewPhoto, setShowPreviewPhoto] = useState(false);
	const [photo, setPhoto] = useState('');
	const [pdfUrl, setPdfUrl] = useState('');
	const isMerchant = useMemo(() => getUserInfo()?.isMerchant ?? false, []);
	const token = getUserInfo()?.token;
	const paymentMethod = useMemo(
		() => countriesState.allPaymentMethod.find((method) => method.methodId === paymentMehodId),
		[paymentMehodId, countriesState.allPaymentMethod],
	);
	const isSell = useMemo(() => orderType === 'SELL', [orderType]);
	const amountLabel = useMemo(
		() => ((isMerchant && !isSell) || (!isMerchant && isSell) ? t<string>('YouReceive') : t<string>('Youpay')),
		[isMerchant, isSell, t],
	);

	const copyMyText = (value: string) => {
		let textToCopy = document.getElementById(value) as HTMLInputElement;
		if (textToCopy) {
			navigator.clipboard.writeText(textToCopy.innerHTML);
			notification['success']({ message: '', description: t<string>('copiedSuccessfully') });
		}
	};
	let tremName = paymentProofFileId.split('.');
	let FileName =
		tremName[0].length > CHAR_SIZE ? tremName[0].slice(0, CHAR_SIZE) + '..' + tremName[1] : paymentProofFileId;

	const downloadFile = () => {
		axios
			.get(
				API_ENDPOINTS.downloadFilePaymentProof
					.replace('%orderNumber%', orderNumber)
					.replace('%fileName%', paymentProofFileLabel),
				{
					responseType: 'arraybuffer',
					headers: { Authorization: `Bearer ${token}` },
				},
			)
			.then((response) => {
				const base64 = btoa(new Uint8Array(response.data).reduce((data, byte) => data + String.fromCharCode(byte), ''));
				if (paymentProofFileLabel.includes('pdf')) {
					fetchPdf(base64);
				} else {
					setPhoto(base64);
				}
			})
			.catch((err) => console.error(err));
	};

	const fetchPdf = (base64Pdf: string) => {
		// Decode the base64 string to Uint8Array
		const uint8Array = decodeBase64(base64Pdf);

		// Create a blob from Uint8Array
		const blob = new Blob([uint8Array], { type: 'application/pdf' });

		// Create an object URL for the blob
		const objectURL = URL.createObjectURL(blob);

		// Set the PDF URL for the viewer
		setPdfUrl(objectURL);
	};
	return (
		<div className='responsive-order-details-wrapper ' dir={getUserLanguageInCookie() === 'ar' ? 'rtl' : 'ltr'}>
			{note ? <Alert message={t<string>('note')} description={note} type='info' /> : null}
			<div className='my-4 mx-[13px] details-container '>
				<div className='details-row'>
					<h3 className='order-details-title'>{t<string>('paymentDetails')}</h3>
					{paymentProof && (
						<div
							className='uploadedFile rounded-2xl flex items-center'
							onClick={() => {
								setShowPreviewPhoto(true);
								downloadFile();
							}}
						>
							<DownloadOutlined style={{ color: '#018aff', fontSize: '18px', margin: '5px 8px' }} />
							{FileName}
						</div>
					)}
				</div>
				{amount !== 'undefined' ? (
					<>
						<div className='details-row px-4 py-1'>
							<p>{t<string>('unitPrice')}</p>
							<div className='flex'>
								<p className='value' id='unitprice'>
									{unitPrice}
								</p>
								<CopyFilled className='mx-2 cursor-pointer' onClick={() => copyMyText('unitprice')} />
							</div>
						</div>
						<div className='details-row px-4 py-1'>
							<p>{t<string>('quantity')}</p>
							<div className='flex'>
								<p className='value' id='quantity1'>
									{quantity}
								</p>
								<CopyFilled className='mx-2 cursor-pointer' onClick={() => copyMyText('quantity1')} />
							</div>
						</div>
						<div className='details-row px-4 py-1'>
							<p>{t<string>('amount')}</p>
							<div className='flex'>
								<p className='value' id='amount1'>
									{amount}
								</p>
								<CopyFilled className='mx-2 cursor-pointer' onClick={() => copyMyText('amount1')} />
							</div>
						</div>
					</>
				) : (
					<Skeleton active paragraph={{ rows: 1 }} />
				)}
				<h3 className='order-details-title'>{t<string>('paywith', { method: paymentMethod?.methodName })}</h3>
				{amount !== 'undefined' ? (
					<>
						<div className='details-row px-4 py-1'>
							<p>{amountLabel}</p>
							<div className='flex'>
								<p className='value' id='pay'>
									{amount}
								</p>
								<CopyFilled className='mx-2 cursor-pointer' onClick={() => copyMyText('pay')} />
							</div>
						</div>
						<div className='details-row px-4 py-1'>
							<p>{t<string>('paymentMethod')}</p>
							<p className='value'>
								<span
									style={{ color: paymentMethod?.color, background: paymentMethod?.bgColor }}
									className={`text-[10px] py-1 px-1 rounded-sm`}
								>
									{paymentMethod?.methodName}
								</span>
							</p>
						</div>
						{isMerchant ? null : (
							<div className='details-row px-4 py-2'>
								<p>{t<string>('account')}</p>
								<div className='flex'>
									<p className='value' id='accountNNumber'>
										{accountGroup} | {accountNumber}
									</p>
									<CopyFilled className='mx-2 cursor-pointer' onClick={() => copyMyText('accountNNumber')} />
								</div>
							</div>
						)}
					</>
				) : (
					<Skeleton active paragraph={{ rows: 1 }} />
				)}
				<h3 className='order-details-title'>{t<string>('releasePayment')}</h3>
			</div>
			<PhotoPreview
				showModal={showPreviewPhoto}
				setShowModal={setShowPreviewPhoto}
				photo={photo}
				paymentProofFileId={paymentProofFileId}
				pdfUrl={pdfUrl}
			/>
		</div>
	);
}

export default ResponsiveBodyDetails;
