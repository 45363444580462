import { Grid, Table } from 'antd';
import { API_ENDPOINTS } from 'assets/api/endpoints';
import axios from 'axios';
import SubHeader from 'components/SubHeader/SubHeader';
import { getUserInfo } from 'helpers/localStorageHandler';
import { WalletAccount } from 'pages/Wallet/types/WalletAccount';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ColumnsFactory } from './columnsFactory';
import { useConfigurationContext } from 'store/configurationContext';
import { StyledPagination } from 'components/table.style';

export const WalletAccounts = () => {
	const { t } = useTranslation();

	const [data, setData] = useState<WalletAccount[]>([]);
	const [isLoading, setIsLoading] = useState(false);

	const { lg } = Grid.useBreakpoint();
	const token = getUserInfo()?.token;

	const columns = useMemo(() => ColumnsFactory({ t }), [t]);
	const { configurationState } = useConfigurationContext();

	useEffect(() => {
		setIsLoading(true);

		axios
			.get<WalletAccount[]>(API_ENDPOINTS.myWallets, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			})
			.then((response) => {
				setData(response?.data);
			})
			.catch(console.error)
			.finally(() => {
				setIsLoading(false);
			});
	}, [token]);
	const themeColor = configurationState.find((item) => item.configKey === 'theme_color')?.value || '';
	const secondary_color = configurationState.find((item) => item.configKey === 'secondary_color')?.value || '';
	return (
		<div>
			<SubHeader title={t('WALLET_ACCOUNTS')} description={t('WALLET_DESCRIPTION')} />
			<div className='min-h-[70vh] max-w-[1440px] mx-auto px-[10px] lg:px-[75px] pt-[30px] pb-10 bg-white'>
				<StyledPagination bgColor={themeColor} color={secondary_color} lg={lg}>
					<Table
						columns={columns}
						dataSource={data}
						loading={isLoading}
						rowClassName={(wallet) => (wallet?.isDefault ? 'highlighted-row' : '')}
					/>
				</StyledPagination>
			</div>
		</div>
	);
};
