import React, { createContext, useEffect, useState, useContext, ReactNode } from 'react';
import axios from 'axios';
import { API_ENDPOINTS } from 'assets/api/endpoints';

// Define the context type

type TComingHolidays = {
	id: number;
	name: string;
	type: 'W' | 'D';
	startDate: string | null;
	endDate: string | null;
	weekDays: [] | null;
	recurring: boolean;
	active: boolean;
};
interface HolidayContextType {
	isTodayHoliday: boolean;
	comingHolidays: TComingHolidays[] | [];
}

// Create context with a default undefined value
const HolidayContext = createContext<HolidayContextType | undefined>(undefined);

export const HolidayProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
	const [isTodayHoliday, setIsTodayHoliday] = useState<boolean>(false);
	const [comingHolidays, setComingHolidays] = useState<TComingHolidays[]>([]);

	useEffect(() => {
		const fetchHolidayStatus = async () => {
			try {
				const response = await axios.get(API_ENDPOINTS.isTodayHolidy);
				setIsTodayHoliday(response.data.todayHoliday);
				setComingHolidays(response.data.comingHolidays);
			} catch (error) {
				console.error('Failed to fetch holiday status', error);
			}
		};

		fetchHolidayStatus();
	}, []);

	return <HolidayContext.Provider value={{ isTodayHoliday, comingHolidays }}>{children}</HolidayContext.Provider>;
};

// Custom hook to expose the holiday context
export const useHoliday = (): HolidayContextType => {
	const context = useContext(HolidayContext);
	if (!context) {
		throw new Error('useHoliday must be used within a HolidayProvider');
	}
	return context;
};
