import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { API_ENDPOINTS } from '../../assets/api/endpoints';
import axios from 'axios';
import { Grid, Table } from 'antd';
import { getMyAccountsColumns } from '../../helpers/myAccountsTableColumns';
import { getUserInfo } from '../../helpers/localStorageHandler';
import { MyAccount } from '../../models/MyAccounts';
import SubHeader from '../../components/SubHeader/SubHeader';
import './MyAccount.scss';
import { useConfigurationContext } from 'store/configurationContext';
import { StyledPagination } from 'components/table.style';

const MyAccounts8 = () => {
	const { t } = useTranslation();
	const [myAccounts, setMyAccounts] = useState<MyAccount[] | null>(null);
	const [myAccountsLoading, setMyAccountsLoading] = useState(false);
	const { configurationState } = useConfigurationContext();
	const { lg } = Grid.useBreakpoint();
	useEffect(() => {
		setMyAccountsLoading(true);
		const token = getUserInfo()?.token;

		axios
			.get(API_ENDPOINTS.accounts, { headers: { Authorization: `Bearer ${token}` } })
			.then((result) => {
				setMyAccounts(result.data);
				setMyAccountsLoading(false);
			})
			.catch((error) => {
				console.error(error);
				setMyAccountsLoading(false);
			});
	}, []);

	const themeColor = configurationState.find((item) => item.configKey === 'theme_color')?.value || '';
	const secondary_color = configurationState.find((item) => item.configKey === 'secondary_color')?.value || '';
	return (
		<div className='my-accounts'>
			<SubHeader title={t<string>('myAccounts')} description={t<string>('myAccountsSubtitle')} />
			<div className='min-h-[70vh] max-w-[1440px] mx-auto px-4 pt-[30px] pb-10 bg-white'>
				<StyledPagination bgColor={themeColor} color={secondary_color} lg={lg}>
					<Table
						columns={getMyAccountsColumns(t)}
						dataSource={myAccounts as MyAccount[]}
						loading={myAccountsLoading}
						tableLayout='auto'
					/>
				</StyledPagination>
			</div>
		</div>
	);
};

export default MyAccounts8;
