import { Button, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { WalletActionType } from '../types/walletActionType';
import { useHoliday } from 'store/HolidayContext';

type WalletActionsProps = {
	className?: string;
	walletActionType: string;
	onAction: (action: WalletActionType) => void;
};

export const WalletActions = (props: WalletActionsProps) => {
	const { t } = useTranslation();
	const { isTodayHoliday } = useHoliday();

	return (
		<article className={`flex gap-2 ${props.className} `}>
			<Tooltip title={isTodayHoliday ? t<string>('holidayDay') : ''}>
				<span>
					<Button
						htmlType='button'
						type='default'
						shape='round'
						className='xss:text-[11px] sm:text-sm xss:!ps-2 xss:!pe-2 md:!ps-4 md:!pe-4 h-9'
						style={{
							backgroundColor: props.walletActionType === WalletActionType.WITHDRAW ? '#0096ff' : 'transparent',
						}}
						onClick={() => props.onAction(WalletActionType.WITHDRAW)}
						disabled={isTodayHoliday}
					>
						{t('WITHDRAW_FROM_MT5')}
					</Button>
				</span>
			</Tooltip>
			<Tooltip title={isTodayHoliday ? t<string>('holidayDay') : ''}>
				<span>
					<Button
						htmlType='button'
						type='default'
						shape='round'
						className='xss:text-[11px] sm:text-sm xss:!ps-2 xss:!pe-2 md:!ps-4 md:!pe-4 h-9'
						style={{ backgroundColor: props.walletActionType === WalletActionType.DEPOSIT ? '#0096ff' : 'transparent' }}
						onClick={() => props.onAction(WalletActionType.DEPOSIT)}
						disabled={isTodayHoliday}
					>
						{t('DEPOSIT_TO_MT5')}
					</Button>
				</span>
			</Tooltip>
		</article>
	);
};
