import styled from 'styled-components';

export const StyledPagination = styled.div<{ bgColor: string; color: string; lg: boolean | undefined }>`
	.ant-table-wrapper {
		margin: ${({ lg }) => (lg ? '20px 0px' : '40px 0px')} !important;
	}
	.ant-pagination-item-active {
		background-color: ${({ bgColor }) => bgColor} !important;
		opacity: 0.9;
	}
	.ant-pagination .ant-pagination-item-active a {
		color: ${({ color }) => color} !important;
	}
`;
