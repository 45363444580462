import { useConfigurationContext } from 'store/configurationContext';
import './SubHeader.scss';
import { headerImage as defaultImage } from 'constant/fallback-config';

interface SubHeaderProps {
	title: string;
	description: string;
	subDescription?: string;
}

const SubHeader = ({ title, description }: SubHeaderProps) => {
	const { configurationState } = useConfigurationContext();
	const headerImage = configurationState.find((item) => item.configKey === 'header_image')?.value || '';
	const hide_header_text = configurationState.find((item) => item.configKey === 'hide_header_text')?.value
		? configurationState.find((item) => item.configKey === 'hide_header_text')?.value === 'true'
		: false;

	return (
		<div className='sub-head' style={{ backgroundImage: `url(${headerImage})` }}>
			{!hide_header_text && (
				<div className='flex flex-col justify-center h-full items-center'>
					<div className='text-[32px] text-white font-bold text-center leading-10'>{title}</div>
					<div className='text-white text-sm text-center leading-10'>{description}</div>
				</div>
			)}
		</div>
	);
};

export default SubHeader;
